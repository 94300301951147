(function ($) {
    $(function () {
        $('.handleOrder').on('click', function (e) {
            e.preventDefault();
            $.get($(this).attr('href'), function (modalContent) {
                $('#orderModal .modal-dialog').html(modalContent.html);
                $('#orderModal').modal('show');
                setupModal();
            });
        });
    });

    function setupModal() {

        $('#orderAddConsumable').off('click').on('click', function (e) {
            e.preventDefault();
            var list = $(this).parents('table').find('tbody');
            prototype_handler(list, list.attr('data-prototype'));
        });

        $('#orderModal tbody').off('collectionElementCreated').on('collectionElementCreated', function (e) {
            $(this).find(".select2").each(function (e) {
                $(this).off('change');
                $(this).select2();
                $(this).on('change', function (e) {
                    var row = $(this).parents('tr');
                    row.find('.form-items-product').val($(this).val());
                    if ($(this).val() != '') {
                        row.find('.form-field-validated').val(1);
                    } else {
                        row.find('.form-field-validated').removeAttr('value');
                    }
                });
            });
        });

        $('#orderModal .changeValidation').off('click').on('click', function (e) {
            e.preventDefault();
            var input = $(this).parent().find('input[id$=validated]');
            var badge = $(this).parent().find('span.badge');
            if (input.val() != "1") {
                input.val("1");
                badge.removeClass('bg-red').addClass('bg-green').text('produit validé');
            } else {
                input.removeAttr('value');
                badge.removeClass('bg-green').addClass('bg-red').text('produit non validé');
            }
        });

        $('#orderModal .changeReference').off('click').on('click', function (e) {
            e.preventDefault();
            var btn = $(this);
            var oldRefContainer = $(this).parent().find("span");
            var oldRef = oldRefContainer.text();

            bootbox.prompt({
                title: "Veuillez saisir une nouvelle référence",
                callback: function (result) {
                    if (result !== null && $.trim(result) != oldRef) {
                        $.post(basePath + '/order/switch-device/' + btn.attr('data-origin') + '/' + result, function (data) {
                            if (data.isValid) {
                                oldRefContainer.text($.trim(result));
                                var oldRefParent = oldRefContainer.parents('tr');
                                oldRefParent.find('.product-line-id input').val(data.id);
                                oldRefParent.find('.device-line-brand').text(data.brand);
                                oldRefParent.find('.device-line-model').text(data.model);
                                oldRefParent.find('.device-line-name').text(data.name);
                            } else {
                                alert(data.message);
                            }

                            if (!$(document).find('body').hasClass('modal-open')) {
                                $(document).find('body').addClass('modal-open');
                            }
                        });
                    }
                }
            });
        });

        $('#orderModal .validate-cart').off('click').on('click', function (e) {
            e.preventDefault();

            var btn = $(this);
            btn.find('i').removeClass('fa-check').addClass('fa-spinner fa-spin');

            $.post(btn.attr('href'), $('#orderModal form').serialize(), function (data) {
                $('#orderModal .modal-dialog').html(data.html);
                setupModal();
                if (data.isValid)
                    document.location.reload();
            });
        });
    }
})(jQuery);
