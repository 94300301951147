(function ($) {
//    window.intlTelInputGlobals.loadUtils(window.location.origin+"/lib/intl-tel-input/build/js/utils.js");
    $(function () {
        function patientCreateAndCoordinate() {
            $('#cenas_form_patient_information_type_country').select2();
            $('#cenas_form_patient_information_type_nationality').select2();
            $('#cenas_form_patient_information_type_patient_doctor').select2();
            $('#cenas_form_patient_information_type_patient_cpapReferral').select2();

            $(document).on('click', '.alpixel_modal_patient_create  .modal-footer .btn.btn-primary', function (e) {
                console.log('valid');
                try {
                    setTimeout(function(){ patientCreateAndCoordinate(); }, 2000);
                    setTimeout(function(){ patientCreateAndCoordinate(); }, 4000);
                } catch (e) {
                    console.log(e);
                    setTimeout(function(){ patientCreateAndCoordinate(); }, 6000);
                }
            });

            // Check de l'existence de patients avec un nom similaire
            //
            var timeoutChecking;
            var lastname = '';
            var firstname = '';
            $('.patientsimilar-checkexisting').on('keyup', function() {
                var inputLastname = $('#cenas_form_patient_information_type_lastname').val().trim();
                var inputFirstname = $('#cenas_form_patient_information_type_firstname').val().trim();
                var inputId = $('#cenas_form_patient_information_type_id').val();

                clearTimeout(timeoutChecking);
                timeoutChecking = setTimeout(function(){
                    var $lastnameCheckExistingBox = $('.patientsimilar-checkexisting-box');
                    var $lastnameCheckExistingBoxItems = $lastnameCheckExistingBox.find('.items');
                    var $lastnameCheckExistingBoxOverlay = $lastnameCheckExistingBox.find('.overlay');
                    var url;

                    if ((inputLastname == lastname) && (inputFirstname == firstname)) {
                        // Pas de changement
                        return;
                    }
                    lastname = inputLastname;
                    firstname = inputFirstname;

                    if (inputLastname.length < 2) {
                        // Il faut au moins un nom de 2 caractères
                        $lastnameCheckExistingBoxItems.html('');
                        $lastnameCheckExistingBox.hide();
                        return;
                    }

                    if (inputId != '') {
                        url = basePath + "/patient/similar/" + inputLastname + "/" + inputId;
                    }
                    else {
                        url = basePath + "/patient/similar/" + inputLastname;
                    }

                    $lastnameCheckExistingBox.show();
                    $lastnameCheckExistingBoxItems.html('<div class="box-body">&nbsp;</div>');
                    $lastnameCheckExistingBoxOverlay.show();
                    $.getJSON(url, {
                        firstName: inputFirstname
                    }, function(data) {
                        $lastnameCheckExistingBoxItems.html('');

                        if (data.patients.length > 0) {
                            $lastnameCheckExistingBoxItems.append( '<div class="box-body"><strong style="color: red;">Attention, des patients avec un nom et prénom similaire existent déjà :</strong></div>' );
                        }

                        $.each( data.patients , function( key, val ) {
                            $lastnameCheckExistingBoxItems.append( '<div class="box-body"><A href="' + basePath + '/patient/file/' + val.id + '/summary" target="_blank">' + val.fullname + '</A></div>' );
                        });

                        $lastnameCheckExistingBoxOverlay.hide();

                        if (data.patients.length == 0) {
                            $lastnameCheckExistingBox.hide();
                        }
                    });
                }, 700);
            });

            $('.input-uppercase').on('keyup', function () {
                $(this).val($(this).val().toUpperCase());
            });

            $('.input-uppercase-firstletter').on('keyup', function () {
                $(this).val($(this).val().charAt(0).toUpperCase() + $(this).val().slice(1).replace(/(?:_|\b)( \w)/g, function(str, p1) { return p1.toUpperCase()}));
            });
        }
        $(document).on('shown.bs.modal', 'alpixel_modal_patient_edit, .alpixel_modal_patient_create ', function (e) {
            try {
                setTimeout(function(){ patientCreateAndCoordinate(); }, 1500);
            } catch (e) {
                console.log(e)
                setTimeout(function(){ patientCreateAndCoordinate(); }, 3000);
            }
        });
        $('form[name="cenas_form_patient_information_type"]').each(patientCreateAndCoordinate);

        $(document).on('shown.bs.modal', '.alpixel_modal_patient_create', function (e) {
            $(this).find("input[name*=lastname], select[name*=birthdate]").on('change', function (e) {
                var lastname = $.trim($('.alpixel_modal_patient_create').find("input[name*=lastname]").val());
                var birthdate = $('.alpixel_modal_patient_create').find("select[name*=birthdate]");

                var day = birthdate.eq(0).val();
                var month = birthdate.eq(1).val();
                var year = birthdate.eq(2).val();

                if (lastname != '' && day != '' && month != '' && year != '') {
                    $.post("/patient/duplicate/"+lastname+"/"+year+"-"+month+"-"+day, function(data) {
                        if(data.error != undefined) {
                            var errorDuplicate = "<div class='col-md-12 error-duplicate'>";
                            errorDuplicate += "<div class='alert alert-warning'>";
                            errorDuplicate += data.message;
                            errorDuplicate += "</div></div>";

                            $('.alpixel_modal_patient_create .modal-body > .row').prepend(errorDuplicate);
                        } else {
                            $('.error-duplicate').remove();
                        }
                    });
                }
            });
        });
    });
})(jQuery);
