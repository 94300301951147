(function($) {
    $(function() {

        function switchAppointment(appModal) {
            var app_bill =  appModal.find(".appointment_billiable_ppc");
			var showBillable = false;

            // On recherche si mode modif (présence sélecteur de type sous forme de boutons radio) ou si mode création
			// (présence multi sélecteur de type sous forme de checkbox)
			console.log(app_bill);
			var appointmentTypeInput = appModal.find("input[name='appointment[type]']");
			if (appointmentTypeInput.length) {
				var radioValue = parseInt(appModal.find("input[name='appointment[type]']:checked").val());
				if (radioValue == 1) {	// 1 = Visite infirmier/technicien
					showBillable = true;
				}
			}
			else {
				var typeNurseVisitChecked = parseInt(appModal.find("input[name='appointment[typeNurseVisit]']:checked").val());
				if (typeNurseVisitChecked) {
					showBillable = true;
				}
			}

			if (showBillable) {
				app_bill.removeClass('hidden');
			}
			else {
				app_bill.addClass('hidden');
			}
        }


        $('#add_appointment_next').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $appointmentDate = $('#appointment_date')
            const patientId = $appointmentDate.data('patient');
            const date = new Date($appointmentDate.val());

            $.ajax({
                type: 'POST',
                url: basePath + '/patient/file/' + patientId + '/appointment/index/date/create',
                data: JSON.stringify(
                    {
                        date: new Date(date),
                    }),
                contentType: "application/json",
                dataType: 'json'
            })
                .done(function (response) {
                    appointmentAddNext(response);
                })
            ;
        });

        function showAllForm() {
            const $formContent = $('#form-content');
            $formContent.removeAttr('hidden');
            $('#add_appointment_next').addClass('hidden');
            $('#appointment_date').prop('readonly', true);

        }

        function appointmentAddNext(data) {
            $('#error-message').text('');
            const $checkbox = $('#appointment_typeCpapControl');
            const $formGroup = $checkbox.closest('.form-group');
            // const $checkboxContainer = $formGroup.children().first();

            const $dateInput = $('#appointment_date');
            const $formGroupForDate = $dateInput.closest('.form-group');

            $formGroup.find(".help-block").remove();
            var isFormValid = true;

            if (data['isValidDate']) {
                $formGroupForDate.removeClass( "has-error");
                $('span:first', $formGroupForDate).remove();

                if (data['cpapCanBeBilled']) {
                    // montre le reste du formulaire après avoir saisi la date.
                    showAllForm();
                } else {
                    // montre le reste du formulaire après avoir saisi la date.
                    showAllForm();
                    disableCheckboxAndDisplayMessage(data);
                }
            } else {
                $('#error-message').text(data['message']);

                $formGroupForDate.removeClass( "has-error");
                $('span:first', $formGroupForDate).remove();

                $formGroupForDate.addClass( "has-error");
                $('#appointment_typeCpapControl_text').append("<span class='help-block'><small>" + data['message']+ "</small></span>")
            }



        }


        if ($("#addAppointment button").hasClass('isNotValid')){
            $("#AddAppointmentPopup").trigger('click');
            setDatePossibleChoice($('#appointment_type input:radio[name="appointment[type]"]:checked'));
        }

        $('#appointmentStep2Form input[type="date"]').datepicker(datepickerOptions);

        // suprimme les asterisks des labels de chaque checkbox
        $('#appointmentForm input[type="checkbox"]').parents('.checkbox').find('label').removeClass('required');

        $('#appointmentForm input[type="checkbox"]').on('change', function(e) {
            if ($('#appointmentForm input:checked').length > 0) {
                // supprime required
                setRequired(false);
            } else {
                // ajout required
                setRequired(true);
            }

            function setRequired(value) {
                if (value === false) {
                    $('#appointmentForm input[type="checkbox"]').removeAttr('required');
                } else {
                    $('#appointmentForm input[type="checkbox"]').prop('required', true);
                }
            }
        });

        // canSubmit avoid recursion
        var canSubmit = true;
        var appointment_typeSelected = false;

        $('#appointmentForm').on('submit', function(e) {
            if (this.reportValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
                return;
            }

            $('#createAppointment').prop('disabled', true);
            if (!canSubmit) {
                var appModal = $('.alpixel_form_modal.alpixel_modal_patient_file_appointment_modal');
                switchAppointment(appModal)
                e.preventDefault();
            }

            var content = $.trim($('#appointment_content').val());
            var date = $.trim($('#appointment_date').val());


            console.log("begin check");
            if(!appointment_typeSelected || content == "" || date == "" ) {
                console.log("radio content date not OK");

            } else {
                console.log("radio content date OK");
                // good
                $("#addAppointment button").removeClass('isNotValid');
            }
        });

        $('#appointment_type input:radio').on('change', function() {
            setDatePossibleChoice(this);
        });

        function disableCheckboxAndDisplayMessage(data){
            var error_messages = [];
            const $checkbox = $('#appointment_typeCpapControl');
            const $formGroup = $checkbox.closest('.form-group');
            const $checkboxContainer = $('#appointment_typeCpapControl_text');
            var isFormValid = false;
            error_messages.push(data['message']);
            $('#appointment_typeCpapControl_label', '#appointmentForm').css("color", "#d2d6de"); // On grise le label
            $('#appointment_typeCpapControl_label i', '#appointmentForm').css("color", "#d2d6de"); // On grise l'icône
            $checkbox.prop('disabled', true); // on désactive la checkbox
            $checkbox.closest('.form-group').addClass( "has-error");
            error_messages.forEach( function(message){
                $checkboxContainer.append("<span class='help-block'><small>" + message + "</small></span>");
            })
        }

        function setDatePossibleChoice(HTMLInputRadio) {
            var appModal = $('.alpixel_form_modal.alpixel_modal_patient_file_appointment_modal');
            switchAppointment(appModal)
            $('#appointmentStep2Form input[type="date"]').val("");

            if(HTMLInputRadio.val() > 0) {
                appointment_typeSelected = true;
            }
        }


        var appModal = $('.alpixel_form_modal.alpixel_modal_patient_file_appointment_modal');
        appModal.on('modal:updated', function(e) {
            switchAppointment(appModal);
            var appoint = appModal.find('#appointment_type');
            appoint.change(function() {
                switchAppointment(appModal);
            });
        });


        var addAppModal = $('#addAppointment')
        addAppModal.on('hidden.bs.modal', function (e) {
            document.location.reload();
        });

        switchAppointment(addAppModal)
        var appoint2 = addAppModal.find('#appointment_typeNurseVisit');
        appoint2.change(function() {
            switchAppointment(addAppModal);
        });
    });
})(jQuery);
