(function ($) {
    $(function () {
        var uriAddAction = $('#tableAddDevice').attr('data-uri');
        var uriAction = "";
        if ($('#addDeviceModal').length > 0) {
            var dataTable = $('#addDeviceModal').find('table');
            dataTable.DataTable('destroy');
        }
        $('#cenas_form_patient_cpap_doctor_type_cpapDoctor').change(function () {

            if ($(this).val() !== '') {
                $("#msg").attr("hidden", true);
            } else {
                $('#msg').removeAttr('hidden');
            }
        });
        $(document).on('click', '#tableAddDevice .addDevice', function () {
            var date = new Date($('#setupDate').val());
            var validDate = false;
            var $cpapDoctorInput = $('#cenas_form_patient_cpap_doctor_type_cpapDoctor');
            var cpapDoctorValue = $cpapDoctorInput.val();


            if (Object.prototype.toString.call(date) === "[object Date]") {
                if (!isNaN(date.getTime())) {
                    if (date > moment()) {
                        alert('Veuillez sélectionner une date antérieure ou égale à la date du jour.');
                    } else {
                        validDate = true;
                    }
                } else {
                    alert('Date invalide');
                }
            } else {
                alert('Date invalide');
            }

            if (validDate) {
                if (cpapDoctorValue === '') {
                    alert('Vous devez d\'abord sélectionner le médecin CPAP du patient');
                } else {

                    var btn = $(this);
                    var deviceId = $(btn).attr('data-device');
                    var actionId = $(btn).attr('data-action');

                    if (cpapDoctorValue === undefined) cpapDoctorValue = '';
                    $.get(uriAddAction + '/' + deviceId + "/" + actionId + "/" + Math.round(date.getTime() / 1000) + '?cpapDoctor=' + cpapDoctorValue).done(function (data) {
                        var success = data[0];
                        var message = data[1];

                        if (success) {
                            message = '<div class="alert alert-success">' + message + '</div>';
                        } else {
                            message = '<div class="alert alert-danger">' + message + '</div>';
                        }

                        $('#addDeviceModal .modal-body').prepend(message);

                        if (success == true) {
                            setTimeout(function () {
                                location.reload();
                            }, 1600);
                        }
                    });
                }
            }
        });

    });
})(jQuery);
