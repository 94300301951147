function updateCheck(divchoice,divabled) {
    if(divchoice.prop("checked")){
        divabled.prop("disabled",false);
        //divabled.prop("required",true);
    } else {
        divabled.prop("disabled",true);
       // divabled.prop("required",false);
        divabled.val("");
    }
}

function updateClass() {
    //Step one
    updateCheck($("#prescription_choiceDiagnosis_3"),$("#prescription_otherDiagnosis"));
    $('input[type="radio"][name="prescription[choiceDiagnosis]"]').click(function(){
        updateCheck($("#prescription_choiceDiagnosis_3"),$("#prescription_otherDiagnosis"));
    });

    //Step Two
    //examen
    var oxy = $("#prescription_examenOximetry");
    updateCheck(oxy,$(".desaturationcpap"));
    oxy.click(function(){
        updateCheck(oxy,$(".desaturationcpap"));
    });

    var poly = $("#prescription_examenPolygraphy");
    updateCheck(poly,$(".indexcpap"));
    poly.click(function(){
        updateCheck(poly,$(".indexcpap"));
    });

    //systeme
    var ncpap = $("#prescription_nCPAP");
    updateCheck(ncpap,$(".ncpap"));
    ncpap.click(function(){
        updateCheck(ncpap,$(".ncpap"));
    });

    //Consommables
    var maskNasal = $("#prescription_maskNasal");
    updateCheck(maskNasal,$(".maskNasal"));
    maskNasal.click(function(){
        updateCheck(maskNasal,$(".maskNasal"));
    });

    var maskNostril = $("#prescription_maskNostril");
    updateCheck(maskNostril,$(".maskNostril"));
    maskNostril.click(function(){
        updateCheck(maskNostril,$(".maskNostril"));
    });

    var maskFullface = $("#prescription_maskFullface");
    updateCheck(maskFullface,$(".maskFullface"));
    maskFullface.click(function(){
        updateCheck(maskFullface,$(".maskFullface"));
    });

    var waterTank = $("#prescription_waterTank");
    updateCheck(waterTank,$(".waterTank"));
    waterTank.click(function(){
        updateCheck(waterTank,$(".waterTank"));
    });

    var otherConsumable = $("#prescription_otherConsummable");
    updateCheck(otherConsumable,$(".otherConsumable"));
    otherConsumable.click(function(){
        updateCheck(otherConsumable,$(".otherConsumable"));
    });
}
(function($){
    $(function(){
        $(".decimal").inputmask('decimal', {  // contraint la saisie de certains champs en alphanumeric
            rightAlign: false
        });

        $('#prescription_printPDF').val(false);
        $("#prescription_validate").val(false);
        updateClass();
        $(".confirm-prescription-duplicate").confirm({
            text: "Voulez-vous préremplir la nouvelle prescription avec les données de la dernière prescription existante ?",
            title: "Préremplir la prescription\n",
            confirm: function(button) {
                window.location.href = button.data("href-confirm");
            },
            cancel: function(button) {
                window.location.href = button.data("href-cancel");
            },
            confirmButton: "Oui",
            cancelButton: "Annuler",
            post: false,
            submitForm: false,
            confirmButtonClass: "btn-warning",
            cancelButtonClass: "btn-default",
            dialogClass: "modal-dialog"
        });

        $(".prescriptionValidateAction").on("click", function (e) {
            $("#prescription_validate").val(true);
			const $submit = $('#submit_buttonValidAndPrintPDF');
			$submit.trigger("click");

        });

        $(".prescriptionValidAndPrintPDF").confirm({
            confirm: function (button) {
                $('#prescription_printPDF').val(true);
                $('button[type="submit"]').trigger('click');
            }
        });

		//Plugins javascript lors de l'ouverture de la modal
		var qrcodeModal = $('.alpixel_modal_prescription_qrcode');
		qrcodeModal.on('modal:updated', function(e) {
			var idPrescription = qrcodeModal.find(".presciption-qrcode");
			var interval = null;
			if(idPrescription.data('qrcode')){
				var i = 185;
				interval = setInterval(
					function() {
							$.post(basePath + '/prescription/qrcode-test/'+idPrescription.data('id'), $(this).serialize(), function (data) {
								switch(data.status){
									case 'ok':
										document.location.reload();
										break;
									case 'nok':
										idPrescription.html(data.message)
										i = 0;
										break;
									default:
										var dt = new Date();
										var time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
										console.log(time);
										i--;
								}
								if(i===0){
									console.log('stop interval');
									clearInterval(interval);
								}
							});
						},
				5000);
			}
		});
	});
})(jQuery);
