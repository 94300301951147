// This file is used when we create a new examination.
// He provide the modal to create a patient, we need to handle that behaviour manually.

(function($) {
    $(function(){
        function manualUpdateModal(modal, route, values, callback) {
            if(xhr != undefined)
                xhr.abort();

            createLoader(modal);
            xhr = jQuery.post(route, values, function(data){

                if(data.submitted == true && data['errors'] == 0) {
                    modal.trigger('modal:show');
                    if (typeof callback === "function" ) {
                        callback('success', data);
                    } else {
                        document.location.reload();
                    }
                } else {

                    modal.find('.modal-dialog').html(data.html);
                    modal.find('.select2').select2();

                    modal.find('form').on('submit', function(e){
                        e.preventDefault();
                        manualUpdateModal(modal, route, jQuery(this).serialize(), callback);
                    });

                    if(modal.is(':visible')) {
                        remoteCallback(modal);
                    } else {
                        modal.one('shown.bs.modal', function(){
                            remoteCallback(modal);
                        });
                        modal.modal('show');
                    }
                }
            });
        }

        function remoteCallback(modal) {
            modal.trigger('modal:updated');
        }

        function createLoader(modal) {
            modal.find('.modal-dialog').html(
                '<div class="modal-content">' +
                    '<div class="modal-header text-center">' +
                        '<i class="fa fa-4x fa-spinner fa-pulse"></i>' +
                        '<br>' +
                        '<h3>Chargement en cours</h3>' +
                    '</div>' +
                '</div>'
            );
        }

        var pathPatientCreate = basePath+'/patient/create';
        var modalPatientCreate = $('#alpixelFormModalPatientCreate');

        if (modalPatientCreate.length) {
            modalPatientCreate.on('shown.bs.modal', function() {

                // No need the search patient modal anymore in this context
                $('#searchPatientModal').modal("hide");
                $('#searchPatientAgenda').modal("hide");

                manualUpdateModal(modalPatientCreate, pathPatientCreate, null, function (state, data) {
                    if (state === 'success') {
                        $('#examination_patient').val(data.patientId);
                        $('#examination_patient_display').val(data.patientName);

                        modalPatientCreate.modal("hide");
                    }
                });
            });
        }
    });
})(jQuery);
