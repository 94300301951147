(function($) {
    $(function() {
        // Allow to manage multiple access for a patient from modal
        // Append li from symfony prototype attribute
        $('.alpixel_modal_patient_file_action_history_access').on('shown.bs.modal', function(e) {
            var ul        = $(this).find('ul[data-prototype]');
            var prototype = ul.attr('data-prototype');
            var items     = $(this).find('ul[data-prototype] > li').length;

            $(this).on('click', '.add-item', function(){
                var li = $('<li />', {
                    html  : prototype.replace(/__name__/g, items)
                    + '<button type="button" class="btn btn-sm btn-danger delete-item">'
                    + '<i class="fa fa-trash"></i>'
                    + 'Supprimer les droits de cet utilisateur'
                    + '</button>'
                }).css({
                    "padding-bottom": "25px",
                    "border-bottom" : "1px solid #ddd",
                    "margin-bottom" : "25px"
                });

                ul.append(li);
                $(li).find('.select2').select2();
                items++;
            });

            $(this).on('click', 'ul[data-prototype] .delete-item', function(e) {
                $(this).parent().remove();
            });
        });
    });

    var dataTableHistory = $('#dataTableHistory');
    if (dataTableHistory.length) {
        dataTableHistory = dataTableHistory.DataTable({
            sDom: ''
        });
        $('#searchDataTableHistory').keyup(function(){
            dataTableHistory.search($(this).val()).draw();
        });
    }
})(jQuery);
