(function($) {
    $(function () {

        var modalHTML = '<div class="modal fade" id="%modal_id%"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h4 class="modal-title">%modal_title%</h4><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div><div class="modal-body"><div class="row"><div class="col-md-8"><div class="form-group"><input type="text" role="search" class="form-control ajax_modal_entity_widget_search_modal" placeholder="Recherche"></div></div></div><table class="table"></table></div></div></div></div>';

        function getHTMLModal(html, id, title) {
            return $(html.replace('%modal_id%', id).replace('%modal_title%', title));
        }

        function getWindowInstances()
        {
            return window.ajax_modal_entity_widget;
        }

        function getWindowDefinition(id)
        {
            var definitions = getWindowInstances();

            for (var i in definitions) {
                var object = definitions[i];
                for (var key in object) {
                    if (key === id) {
                        return object[key];
                    }
                }
            }
        }

        function callbackWindowDefinitions(fn)
        {
            var definitions = getWindowInstances();

            for (var i in definitions) {
                var object = definitions[i];
                for (var key in object) {
                    fn(object[key]);
                    break;
                }
            }
        }

        function initModals() {
            callbackWindowDefinitions(function (definition) {
                var $modal = getHTMLModal(modalHTML, 'modal_' + definition.id, definition.label);

                $('body').append($modal);
            });
        }


        initModals();

        $(document).on('click', 'button.ajax_modal_entity_widget_btn', function (e) {
            e.preventDefault();

            var id = $(this).attr('data-id');
            var definition = getWindowDefinition(id);

            var url = definition.url;
            var modal = '#modal_'+id;
            var table = modal+' table';
            var buttonModal = '#button_'+id;
            var $icon = $(buttonModal+' i');
            var $modal = $(modal);

            if (definition.ajaxInstance != null) {
                definition.ajaxInstance.abort();
            }

            $icon.removeClass('fa-search-plus').addClass('fa-spinner fa-pulse');

            definition.ajaxInstance = $.get(url, function (data) {
                var $table = $modal.find('table');

                if ($.fn.DataTable.isDataTable($table) ) {
                    $table.DataTable().destroy();
                }

                $table.html(data);

                var dataTable = $table.DataTable({
                    responsive: true,
                    lengthChange: false,
                    dom: 'lrtip',
                    pageLength: 8,
                    deferRender: true
                });

                $icon.removeClass('fa-spinner fa-pulse').addClass('fa-search-plus');

                $modal.modal('show');

                $modal.find('.ajax_modal_entity_widget_search_modal').on('keyup', function () {
                    dataTable
                        .search( $(this).val() )
                        .draw();
                });

                $table.on('click', '.ajax_modal_entity_widget_action', function () {
                    var entityId = $(this).attr('data-id');
                    var text = $(this).attr('data-text');
                    $('#display_'+id).val(text);
                    $('#'+id).val(entityId);
                    $modal.modal('hide');
                });
            });
        });
    });
})(jQuery);
