(function($) {
    $(function() {

        var xhr;
        $(document).on('click', '.showModal.showDeviceBilling', function(e){

            e.preventDefault();
            var btn           = $(this);
            var patientId     = btn.attr('data-patient');
            var sessionPeriod =  '';

            if(btn.is('[data-session]')) {
                sessionPeriod = '?session='+btn.attr('data-session');
            }

            if(xhr != undefined) {
                xhr.abort();
            }

            $('table i.fa.fa-spinner').hide();
            btn.find('i').show();

            xhr = $.get(basePath+'/billing/devices/'+patientId+'/modal'+sessionPeriod, function(modalContent) {

                btn.find('i').hide();
                if($('.modal-patient-'+patientId).length > 0) {
                    $('.modal-patient-'+patientId).remove();
                }

                var modal = $(modalContent).appendTo("body");
                modal.modal('show');

                modal.find('.delete-item').on('click', function(e){
                    e.preventDefault();
                    $(this).parent().parent().remove();
                });
            });
        });
        $('.form-filter').on('change', function(e){
            $('#bill_list_filter_form').trigger('submit');
        });

    });
})(jQuery);
