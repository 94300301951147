(function ($) {
    $(function () {

        function actionCreateBrand(brandSelector) {
            brandSelector.find('#consumable_new_brand').removeClass('hidden');
            brandSelector.find('#consumable_brand').next().addClass('hidden');
            brandSelector.find('#consumable_new_brand_btn')
                .text(' Annuler')
                .removeClass('fa-plus btn-primary')
                .addClass('fa-minus btn-warning');
        }

        function actionSelectBrand(brandSelector) {
            brandSelector.find('#consumable_new_brand').addClass('hidden').val('');
            brandSelector.find('#consumable_brand').next().removeClass('hidden');
            brandSelector.find('#consumable_new_brand_btn')
                .text(' Créer une nouvelle marque')
                .removeClass('fa-minus btn-warning')
                .addClass('fa-plus btn-primary');
        }

        $('.alpixel_modal_stock_cenas_consumables_create').on('shown.bs.modal', function (e) {
            var _this = $(this);
            $('#consumable_new_brand_btn').click(function (e) {
                if (_this.find('#consumable_new_brand').hasClass('hidden')) {
                    actionCreateBrand(_this);
                } else {
                    actionSelectBrand(_this);
                }
            });
        });


    });
})(jQuery);
