(function ($) {
    $(function () {

        var xhr;
        $('.showPatientBillingModal').on('click', function (e) {
            e.preventDefault();

            var btn = $(this);
            var patientId = btn.attr('data-patient');

            if (xhr != undefined) {
                xhr.abort();
            }

            $('table i.fa.fa-spinner').hide();
            btn.find('i').show();

            xhr = $.get(basePath + '/patient/file/' + patientId + '/bill/create', function (modalContent) {

                btn.find('i').hide();
                if ($('.modal-patient-' + patientId).length > 0) {
                    $('.modal-patient-' + patientId).remove();
                }

                var modal = $(modalContent).appendTo("body");
                modal.modal('show');

                modal.find('.delete-item').on('click', function (e) {
                    e.preventDefault();
                    $(this).parent().parent().remove();
                });
            });
        });
    });
})(jQuery);
