(function($) {
    $(function() {

        var xhr;
        $('.showBillingShowModal').on('click', function(e){
            e.preventDefault();

            var btn    = $(this);
            var billId = btn.attr('data-bill');

            if(xhr != undefined) {
                xhr.abort();
            }

            $('table i.fa.fa-spinner').hide();
            btn.find('i').show();

            xhr = $.get(basePath+'/billing/show/'+billId+'/modal', function(modalContent) {
                btn.find('i').hide();
                if($('.modal-bill-'+billId).length > 0) {
                    $('.modal-bill-'+billId).remove();
                }

                var modal = $(modalContent).appendTo("body");
                modal.modal('show');

                modal.find('.delete-item').on('click', function(e){
                    e.preventDefault();
                    $(this).parent().parent().remove();
                });
            });
        });
    });
})(jQuery);
