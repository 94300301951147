(function($){

    function displayAllNote(show, btn){
         if(show == true) {
            $('.truncated-text').hide();
            $('.original-text').removeClass('hidden');
            $(btn).removeClass('show-all-note btn-primary').addClass('hide-all-note btn-warning').text('Réduire tout');
        }
        else {
            $('.truncated-text').show();
            $('.original-text').addClass('hidden');
            $(btn).removeClass('hide-all-note btn-warning').addClass('show-all-note btn-primary').text('Afficher Tout');
        }

        return;
    }

    $('.show-all-note, .hide-all-note').on('click', function(){
        var btn    = $(this);
        var show   = $(btn).hasClass('show-all-note');

       displayAllNote(show, btn);
    })

})(jQuery);
