(function($){
    $(function(){
        $(document).on('click', 'table tr[data-link]',function(ev){
            var link = $(this).attr('data-link');

            if (link.match('/^http:\/\//i') === null) {
                link = 'http://'+link;
            }

            window.open(link, '_blank');
        });
        $(document).on('click', '.archiveNote', function(e) {
            e.preventDefault();
            $.post($(this).attr('href'));
            $(this).parent().parent().remove();
        });
    });
})(jQuery);
