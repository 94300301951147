(function($){
    $(function(){
        var ATTRIBUTION_MUST_EQUAL = 100;

        function appendInput(elementToAppend, doctorPrototype, amountPrototype, i)
        {
            elementToAppend.append(
                $(document.createElement('tr'))
                    .append($(document.createElement('td'))
                        .append(doctorPrototype.replace(/__name__/g, i))
                    )
                    .append($(document.createElement('td'))
                        .append(amountPrototype.replace(/__name__/g, i))
                    )
                    .append($(document.createElement('td'))
                        .append('<button type="button" class="btn btn-sm btn-danger doctors-delete"><i class="fa fa-trash"></i></button>')
                    )
            );

            elementToAppend.on('click', '.doctors-delete', function()
            {
                $(this).closest('tr').remove();
            });
        }

        function checkTotalPercent(parentElement)
        {
            var percent = 0;

            parentElement.find('select[id$="_share"] option:selected').each(function(index, input){
                percent += parseInt($(this).text());
                if(percent > ATTRIBUTION_MUST_EQUAL)
                    return percent;
            });

            return percent;
        }

        function uniqueHigherPercent(parentElement)
        {
            var percentTab = [];

            parentElement.find('select[id$="_share"] option:selected').each(function(index, input){
                percentTab.push(parseInt($(this).text()));
            });
            percentTab.sort();

            console.log(percentTab.length);
            console.log(percentTab[0]);
            console.log(percentTab[1]);
            if(percentTab.length == 2 && percentTab[0] == 50 && percentTab[1] == 50) {
                return true;
            }

            return (percentTab.pop() !== percentTab.pop()) ? true : false;
        }

        $('.alpixel_form_modal.alpixel_modal_patient_file_examination_assign_report').on('modal:updated', function(){
            var elementToAppend, items, doctorPrototype, amountPrototype;

            elementToAppend = $(this).closest('.modal-body').find('tbody#assignReport');
            items           = elementToAppend.find('tr').length;
            doctorPrototype = elementToAppend.attr('data-prototype-doctor');
            amountPrototype  = elementToAppend.attr('data-prototype-amount');

            if(items === 0) {
                for (items; items <= 1; items++) {
                    //appendInput(elementToAppend, doctorPrototype, amountPrototype, items);
                }
            }
        });

        $('.alpixel_form_modal.alpixel_modal_patient_file_examination_assign_report').on('click', '.doctors-add', function () {
            var elementToAppend, items, doctorPrototype, amountPrototype;

            elementToAppend = $(this).closest('.modal-body').find('tbody#assignReport');
            items = elementToAppend.find('tr').length;
            doctorPrototype = elementToAppend.attr('data-prototype-doctor');
            amountPrototype = elementToAppend.attr('data-prototype-amount');

            if (items >= 3)
                return;

            appendInput($('tbody#assignReport'), doctorPrototype, amountPrototype, items);
            items++;
        });

        $('.alpixel_form_modal.alpixel_modal_patient_file_examination_assign_report').on('click', '.doctors-delete', function()
        {
            $(this).closest('tr').remove();
        });

        $('.alpixel_form_modal.alpixel_modal_patient_file_examination_assign_report').on('click', 'button.submit', function () {
            $(this).closest('.modal-body').find('tbody#assignReport').closest('form').submit();
        });
    });
})(jQuery);
