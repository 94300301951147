(function ($) {
    $(function () {

        function actionCreateType(typeSelector) {
            typeSelector.find('#consumable_new_type').removeClass('hidden');
            typeSelector.find('#consumable_type').next().addClass('hidden');
            typeSelector.find('#consumable_new_type_btn')
                .text(' Annuler')
                .removeClass('fa-plus btn-primary')
                .addClass('fa-minus btn-warning');
        }

        function actionSelectType(typeSelector) {
            typeSelector.find('#consumable_new_type').addClass('hidden').val('');
            typeSelector.find('#consumable_type').next().removeClass('hidden');
            typeSelector.find('#consumable_new_type_btn')
                .text(' Créer une nouvelle famille')
                .removeClass('fa-minus btn-warning')
                .addClass('fa-plus btn-primary');
        }

        $('.alpixel_modal_stock_cenas_consumables_create').on('shown.bs.modal', function (e) {
            var _this = $(this);
            $('#consumable_new_type_btn').click(function (e) {
                if (_this.find('#consumable_new_type').hasClass('hidden')) {
                    actionCreateType(_this);
                } else {
                    actionSelectType(_this);
                }
            });
        });


    });
})(jQuery);
