(function($)
{
    $('#cenas_notification_type_date').datepicker(datepickerOptions);

    $('.alpixel_modal_patient_file_action_history_appointment').on('modal:before-reload', function(e){
        window.open(basePath+'/notification/pool/download');
    });

    $('form[name="cenas_patient_action_appointment_type"]').on('click', 'input[type="submit"]',function(e){
        e.stopImmediatePropagation();
        e.preventDefault();
        if(noteValidated === true)
                $('form[name="cenas_patient_action_appointment_type"]').submit();
        else {
            var confirm = window.confirm('La note n\'a pas été validé, voulez-vous quand même envoyer la prise de rendez-vous ?');

            if(confirm == true)
                $('form[name="cenas_patient_action_appointment_type"]').submit();
        }
    });

    $('#mandatory-date-modal input[type="text"]').datepicker(datepickerOptions);

    $('#mandatory-date-modal a.btn').click(function (event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        var _this = $(this);
        var date = $.trim($('#mandatory-date-modal input[type="text"]').val());

        if (date != '') {
            var link = _this.attr('href');

            window.location = link.replace('date', encodeURIComponent(date));
        }

        return false;
    });
})(jQuery);

