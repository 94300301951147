(function($) {
    $(function () {

        $('a.add-to-cart').on('click', function(e){
            e.preventDefault();
            var btn = $(this);
            var quantity = 1;

            if(btn.attr('data-quantity') != undefined) {
                bootbox.prompt({
                  title: "Quelle quantité ? ",
                  value: "1",
                  locale: "fr",
                  className: "cart-quantity-prompt",
                  buttons: {
                    confirm: {
                      label: "Valider",
                      className: "btn-primary"
                    },
                    cancel: {
                      label: "Annuler"
                    },
                  },
                  callback: function(result) {
                    if(result !== null) {
                        quantity = parseInt(result);
                        if(isNaN(quantity))
                            return false;
                        addToCart(btn, quantity);
                    }
                  }
                });
            } else {
                addToCart(btn, quantity);
            }
        });

        $('#cartLoader').on('click', function(e){
            e.preventDefault();
            if($('#cartModal').length == 0) {
                var modal = $('<div class="modal fade" id="cartModal"><div class="modal-dialog"></div></div>');
                modal.hide();
                $("body").append(modal);
            }

            $.get(basePath+'/cart/show', function(data){
                $('#cartModal .modal-dialog').html(data);
                $('#cartModal').modal('show');
                setCartQuantityHandler();
            });


        });
    });

    function addToCart(btn, quantity) {
        btn.find('i')
            .removeClass('fa-plus')
            .addClass('fa-spinner fa-spin')
        ;

        $.post(basePath+'/cart/add/'+btn.attr('data-type')+'/'+btn.attr('data-id')+'/'+quantity, function(data){
            btn.find('i')
                .addClass('fa-plus')
                .removeClass('fa-spinner fa-spin')
            ;

            if(data['error'] != undefined) {
                createAlert('warning', data['error'], 3000);
            } else {
                if(btn.parent().find('span.hidden').length > 0) {
                    btn.hide();
                    btn.parent().find('span').removeClass('hidden');
                } else {
                    createAlert('success', 'Produit correctement ajouté au panier', 3000);
                }

                var badge = $('#cart-count').find('.badge');
                var count = parseInt(badge.text());
                badge.text((count+1).toString());
            }
        });
    }

    function setCartQuantityHandler() {
        var timeout;
        var xhr;

        $('#cartModal .cart-quantity-handler').on('keyup', function(){
            if(timeout != undefined)
            {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function(){

                if(xhr != undefined)
                    xhr.abort();

                xhr = $.post(basePath+'/cart/calculate', $('#cartModal form').serialize(), function(data){
                    $('#cartModal .modal-dialog').html(data);
                    setCartQuantityHandler();
                    reloadBadge();
                });
            }, 500);
        });

        $('#cartModal .delete-cart-item').on('click', function(e){
            e.preventDefault();
            var row = $(this).parent().parent();
            $.post($(this).attr('href'), function(data){
                $('#cartModal .modal-dialog').html(data);
                setCartQuantityHandler();
                reloadBadge();
            });
        });

        $('#cartModal .empty-cart').on('click', function(e){
            e.preventDefault();
            $.post($(this).attr('href'), function(data){
                $('#cartModal').modal('hide');
                reloadBadge();
            });
        });

        $('#cartModal .validate-cart').on('click', function(e){
            e.preventDefault();
            var btn = $(this);
            btn.find('i').removeClass('fa-check').addClass('fa-spinner fa-spin');

            $.post($(this).attr('href'), function(data){
                btn.find('i').addClass('fa-check').removeClass('fa-spinner fa-spin');
                if(data.isValid) {
                    var url   = btn.attr('data-target');
                    var input = $('#cartModal').find('#cart_note').clone();
                    var form  = $('<form>', {
                        'method': 'POST',
                        'action': url
                    }).append(input);

                    $('body').append(form);

                    form.submit();
                } else {
                    $.post(basePath+'/cart/calculate', $('#cartModal form').serialize(), function(data){
                        $('#cartModal .modal-dialog').html(data);
                        setCartQuantityHandler();
                        reloadBadge();
                    });
                    alert('Votre panier comporte des erreurs, merci de bien vouloir les corriger');
                }
            });
        });
    }
})(jQuery);
