(function ($) {
    $(function () {
        $('.alpixel_modal_patient_file_action_history_merge').on('show.bs.modal', function (ev) {
            $(this).on('click', 'input[type="submit"]', function (ev) {
                console.log('alpixel_modal_patient_file_action_history_merge');
                $(document).ajaxComplete(function (ev, xhr, settings) {
                    if (xhr.responseJSON != undefined && xhr.responseJSON.merge_success != undefined) {
                        if (xhr.responseJSON.merge_success === true) {
                            document.location.reload();
                        }
                    } else {
                        alert('Une erreur est survenue.');
                    }
                });
            });
        });

        $('.alpixel_modal_patient_file_action_history_merge').on('hide.bs.modal', function (ev) {
            document.location.reload();
        });
    });
})(jQuery);
