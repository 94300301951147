(function ($) {
    var isChecked = false;
    $(function () {
        if ($('#cenas_document_new_form_media').length > 0) {

            var dropzone = $('#cenas_document_new_form_media').get(0).dropzone;
            $('#cenas_document_new_form_media').after('<em>(Formats acceptés : ' + dropzone.options.acceptedFiles + ')</em>');

            dropzone.on('sending', function (file) {
                $(file.previewElement).find('.dz-progress').show();
            });

            dropzone.on('complete', function (file) {
                $(file.previewElement).find('.dz-progress').hide();
            });

            dropzone.on('success', function (file) {
                $(file.previewElement).find('.data-dz-filename').text(file.name);
                $('#addMediaBtn').show();
                $('#addMediaBtn span').text(dropzone.files.length.toString());
            });

            dropzone.on('removedfile', function (file) {
                var count = dropzone.files.length;
                if (count === 0) {
                    $('#addMediaBtn').hide();
                } else {
                    $('#addMediaBtn').show();
                    $('#addMediaBtn span').text(count.toString());
                }

                var uploadedFiles = [];
                $(dropzone.files).each(function (i, el) {
                    var res = JSON.parse(dropzone.files[i].xhr.response);
                    uploadedFiles.push(res[0]['id']);
                });

                $("div#cenas_document_new_form_media").find('input[type="hidden"]').val(uploadedFiles.join('#&#'));
            });

            dropzone.on('error', function (file) {
                alert('Ce type de fichier n\'est pas accepté ou une erreur est survenue.');
            });

            $('form[name="cenas_document_new_form"]').on('submit', function (e) {
                if (dropzone.getUploadingFiles().length > 0) {
                    e.preventDefault();
                    bootbox.dialog({
                        title: "Fichier en cours de téléchargement",
                        message: "Merci d'attendre la fin de l'envoi de tous vos fichiers",
                        buttons: {
                            success: {
                                label: "Retour"
                            }
                        }
                    });
                    return;
                }
                if (!isChecked) {
                    e.preventDefault();
                    var files = $('#cenas_document_new_form_media input').val().split('#&#');

                    if (files.length == 0 || files[0] == "") {
                        bootbox.dialog({
                            title: "Aucun fichier",
                            message: "Vous n'avez téléchargé aucun fichier",
                            buttons: {
                                success: {
                                    label: "Retour"
                                }
                            }
                        });
                    } else {
                        $('#mediaContent').html("");
                        displayPrompt(dropzone, files, 0);
                    }
                }
            });
        }
    });

    function displayPrompt(dropzone, files, i) {
        if (dropzone.files[i] != undefined) {
            bootbox.prompt({
                title: "Merci de saisir une description pour le fichier " + dropzone.files[i].name,
                callback: function (result) {
                    if (result !== null) {
                        $('#mediaContent').append("<input type='hidden' name='content_" + files[i] + "' value='" + result + "' />");
                    }
                    if (files.length > i + 1)
                        displayPrompt(dropzone, files, i + 1);
                    else {
                        isChecked = true;
                        $('form[name="cenas_document_new_form"] button[type="submit"]').trigger('click');
                    }
                }
            });
        }
    }
})(jQuery);
