(function ($) {
    $(function () {
        var submitted = false;
        $('.alpixel_modal_patient_file_action_history_appointment').on('show.bs.modal', function (ev) {
            $(this).on('click', 'input[type="submit"]', function (ev) {
                $(document).ajaxComplete(function (ev, xhr, settings) {
                    if (xhr.responseJSON != undefined && xhr.responseJSON.appointment_success != undefined) {
                        if (xhr.responseJSON.appointment_success === true) {
                            document.location.reload();
                        }
                    } else {
                        alert('Une erreur est suvernue.');
                    }
                });
            });
        });

        $('.alpixel_modal_patient_file_action_history_appointment').on('hide.bs.modal', function (ev) {
            document.location.reload();
        });
    });
})(jQuery);
