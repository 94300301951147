var datepickerOptions = {
    language: "fr",
    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    daysMin: ["D", "L", "Ma", "Me", "J", "V", "S", "D"],
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jui", "Jul", "Aou", "Sep", "Oct", "Nov", "Déc"],
    today: "Aujourd'hui",
    clear: "Effacer",
    weekStart: 1,
    format: {
        toDisplay: function (date, format, language) {
            return moment(date).format("YYYY-MM-DD");

        },
        toValue: function (date, format, language) {
            return moment(date, "DD/MM/YYYY");
        }
    },
    calendarWeeks: true,
    autoclose: true,
    zIndexOffset: 2000
};

$.confirm.options = {
    text: "Êtes-vous certain de vous vouloir effectuer cette action ?",
    title: "Confirmation requise",
    confirmButton: "Oui",
    cancelButton: "Annuler",
    post: false,
    submitForm: false,
    confirmButtonClass: "btn-warning",
    cancelButtonClass: "btn-default",
    dialogClass: "modal-dialog"
};

function prototype_handler(collectionHolder, prototype) {
    var index = jQuery(collectionHolder).children().length;
    var newForm = prototype.replace(/__name__/g, index);
    jQuery(collectionHolder).append(newForm);
    jQuery(collectionHolder).trigger("collectionElementCreated");
}

function createAlert(type, message, delay) {
    var container = document.getElementById('alert-container');

    var alert = jQuery('<div class="alert alert-' + type + ' alert-dismissable" style="display: none;">' + message + '</div>').appendTo(container);
    alert.fadeIn(100, function () {
        setTimeout(function () {
            alert.fadeOut(function () {
                jQuery(this).remove();
            });
        }, delay);
    });
}

function hasDataTable(nTable) {
    var settings = $.fn.dataTableSettings;
    for (var i = 0, iLen = settings.length; i < iLen; i++) {
        if (settings[i].nTable == nTable) {
            return true;
        }
    }
    return false;
}

function reloadBadge() {
    jQuery.get(basePath + '/cart/count', function (data) {
        jQuery('#cartLoader .badge').text(data);
    });
}

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function modelMatcher(params, data) {
    data.parentText = data.parentText || "";

    // Always return the object if there is nothing to compare
    if ($.trim(params.term) === '') {
        return data;
    }

    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);

        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + " " + data.text;

            var matches = modelMatcher(params, child);

            // If there wasn't a match, remove the object in the array
            if (matches == null) {
                match.children.splice(c, 1);
            }
        }

        // If any children matched, return the new object
        if (match.children.length > 0) {
            return match;
        }

        // If there were no matching children, check just the plain object
        return modelMatcher(params, match);
    }

    // If the typed-in term matches the text of this term, or the text from any
    // parent term, then it's a match.
    var original = (data.parentText + ' ' + data.text).toUpperCase();
    var term = params.term.toUpperCase();


    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
        return data;
    }

    // If it doesn't contain the term, don't return anything
    return null;
}

(function ($) {

	/**
	 * Fix select2 for Firefox http://stackoverflow.com/a/19574076
	 */
	$.fn.modal.Constructor.prototype.enforceFocus = function() {};

	function initializeDom(dom) {
	    console.log(dom);
        $(dom).on('click', 'input[readonly], select[readonly]', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        $(dom).on('focus', 'input.readonly, textarea.readonly', function (e) {
            $(this).blur();
        });
    }

    $(function () {
        initializeDom(document);

        $('.select2').select2();
        $(".confirm-button").confirm();

        $('[data-toggle="tooltip"]').tooltip();

        $('.confirmDelete').on('click', function (e) {
            e.preventDefault();
            var link = $(this);
            bootbox.dialog({
                title: "Demande de confirmation",
                message: "Confirmez-vous cette action ?",
                buttons: {
                    danger: {
                        label: "Annuler"
                    },
                    success: {
                        label: "Oui",
                        className: "btn-success",
                        callback: function (result) {
                            if (result) {
                                document.location.href = link.attr('href');
                            }
                        }
                    }
                }
            });
        });

        $('div.icheck input').each(function (i, el) {
            var color = "blue";
            if ($(this).attr('data-color') != undefined) {
                color = $(this).attr('data-color');
            }

            $(this).iCheck({
                checkboxClass: 'icheckbox_flat-' + color,
                radioClass: 'iradio_flat-' + color,
                increaseArea: '20%' // optional
            });
        });

        $(document).on('click', 'input[type="date"]', function (e) {
            e.preventDefault();
        });

        $(document).on('click', '.trigger-truncate-action', function (e) {
            e.preventDefault();
            var parent = $(this).parent();
            if (parent.find('.original-text').hasClass('hidden')) {
                parent.find('.original-text').removeClass('hidden');
                parent.find('.truncated-text').hide();
                $(this).find('i').removeClass('fa-plus').addClass('fa-minus');
            } else {
                parent.find('.truncated-text').show();
                parent.find('.original-text').addClass('hidden');
                $(this).find('i').removeClass('fa-minus').addClass('fa-plus');
            }
        });

        $(document).on('click', '.trigger-truncate-all-action', function (e) {
            e.preventDefault();
            var parent = $(this).parent();
            if ($(this).find('i').hasClass('fa-plus')) {
                $('.original-text').removeClass('hidden');
                $('.truncated-text').hide();
                $(this).find('i').removeClass('fa-plus').addClass('fa-minus');
            } else {
                $('.truncated-text').show();
                $('.original-text').addClass('hidden');
                $(this).find('i').removeClass('fa-minus').addClass('fa-plus');
            }
        });


        //Gestion des submodals de recherche
        $(document).on('click', '.triggerSearchButton', function (e) {

            e.preventDefault();

            var idModal = $(this).attr('data-target');
            var modal = $(idModal);

            var icon = $(this).find('i');
            icon.removeClass('fa-search-plus');
            icon.addClass('fa-spinner fa-pulse');

            $.get(modal.attr('data-source'), function (data) {

                modal.find('.searchData').replaceWith(data);
                modal.find('.searchDataPagin').replaceWith(data);


                dataTable = modal.find('.searchData').DataTable({
                    responsive: true,
                    pageLength: 8,
                    lengthChange: false,
                    dom: 'lrtip'
                });

                $(modal).on('click', '.patientPagin ul li span', function (e) {
                    modal.find('.searchDataPagin').find('table').hide();
                    $('.loadingPagin').show();
                    $.get($(this).attr('data-href'), function (data) {
                        modal.find('.searchDataPagin').html(data);

                    });
                });

                //DEL/ Cette fonction semble avoir été remplacé par updateInputForm dans examination_form.js
                modal.find('.searchData button.chooseBtn').on('click', function (e) {
                    modal.data("selectedValue", $(this).attr('data-id'));
                    modal.trigger('modal:value:selected'); //On dispatch un évènement qui remonte la valeur
                    modal.modal('hide');
                });
                modal.modal('show');

                icon.addClass('fa-search-plus');
                icon.removeClass('fa-spinner fa-pulse');
            });
        });

        //nettoyage du datatable pour éviter les ralentissements/bug
        $('.searchDatatableModal').on('hidden.bs.modal', function () {
            $(this).find('table').DataTable().destroy();
        });

        //Recherche manuel sur le datatable depuis un filtre custom
        $('.searchDatatableModal .formFilter').keyup(function () {
            var div = $(this).parentsUntil('.searchDatatableModal').find('table').parent();
            if(div.hasClass('searchDataPagin')){
                if($(this).val().length > 2) {
                    div.find('table').hide();
                    $('.loadingPagin').show();
                    $.post(basePath + '/patient/datalist', {search: $(this).val()}, function (data) {
                        div.html(data);
                    });
                }
            }else{
                var dataTable = $(this).parentsUntil('.searchDatatableModal').parent().find('table').DataTable();
                dataTable.search($(this).val()).draw();
            }
        });

        function clearForm(form) {
            $(':input', form).each(function () {
                var type = this.type;
                var tag = this.tagName.toLowerCase();
                if (type === 'text' || type === 'password' || tag === 'textarea') {
                    $(this).val(null);
                }
                else if (type === 'checkbox' || type === 'radio') {
                    $(this).attr('checked', false);
                }
                else if ($(this).hasClass('select2')) {
                    $(this).select2('val', null);
                }
                else if (tag === 'select') {
                    $.each(this, function (index, value) {
                        if (index === 0) {
                            $(this).attr('selected', true);
                        }
                        else {
                            $(this).attr('selected', false);
                        }
                    })
                }
            });
        }

        $(document).one('click', 'button.reset-cookie-form-action', function (ev) {
            ev.preventDefault();
            ev.stopImmediatePropagation();

            var form = $(this).closest('form').get(0);
            if (form.length) {
                clearForm(form);
                $(form).trigger('reset');
                $(form).trigger('submit');
            }
        });

        $(document).on('hidden.bs.modal', function (e) {
            if ($(e.target).attr('data-refresh') == 'true') {
                // Remove modal data
                $(e.target).data('bs.modal', null);
            }
        });

        $(document).on('modal:updated', function (e) {
            $(e.target).find('.select2').select2({
                matcher: modelMatcher
            });

            formatterForm();

            initializeDom(e.target);
        });

        $('table.displayDataTable').DataTable({
            responsive: true,
            pageLength: 8,
            lengthChange: false,
            dom: 'lrtip'
        });

        $('table tr[data-link]').on('click', function (ev) {
            var link = $(this).attr('data-link');

            if (link.match('/^http:\/\//i') === null) {
                link = 'http://' + link;
            }

            window.open(link, '_blank');
        });

        $('.sidebar-toggle').on('click', function (e) {
            if ($("body").hasClass('sidebar-collapse')) {
                Cookies.remove('sidebar');
            } else {
                Cookies.set('sidebar', 'collapse', {expires: 7});
            }
        });

		/**
		 * Fix for Firefox add disabled attribute on submit input when the form has been submitted from a modal
		 */
		$(document).on('click', '.modal button[type="submit"]', function () {
			var element = $(this);
			var text = element.text();

			$(document).ajaxStart(function() {
				element
					.text('Traitement en cours ...')
					.attr('disabled', true);
				$(document).ajaxStop(function() {
					element
						.removeAttr('disabled')
						.text(text);
				});
			});
		})
    });


    function formatterForm() {
        $('.phone-formatter').formatter({
            'pattern': '+{{99}} {{99}} {{999}} {{99}} {{99}}',
            'persistent': false
        });
        // $('.phone-iti-formatter').formatter({
        //     'pattern': '+{{99}} {{99}} {{999}} {{99}} {{99}}',
        //     'persistent': false
        // });
        $('.avs-formatter').formatter({
            'pattern': '{{999}}.{{9999}}.{{9999}}.{{99}}',
            'persistent': false
        });
        $('.accident-formatter').formatter({
            'pattern': '{{99999999999999999999}}',
            'persistent': false
        });
        $('.date-formatter').formatter({
            'pattern': '{{99}}/{{99}}/{{9999}}',
            'persistent': false
        });
    }
    formatterForm();
})(jQuery);


//Gestion des submodals de recherche
$(document).on('click', '.seeHidden', function (e) {

    var div = $(this).attr('data-target');
    $("#"+div).toggleClass('hidden');
});

function newUpdateModal(modal, values, icon, triggerReload) {
    if(xhr != undefined)
        xhr.abort();

    console.log(triggerReload);
    xhr = jQuery.post(modal.attr('data-source'), values, function(data){
        if(data['submitted'] == true && data['errors'] == 0) {
            modal.trigger('modal:show');
            if(data['no-reload'] != undefined){
                modal.modal('hide');
            } else {
                if(data['callback'] != undefined){
                    document.location.href = data['callback'];
                } else {
                    document.location.reload();
                }
            }
            if(triggerReload != undefined){
                $(window).trigger(triggerReload);
            }
        } else {
            if(icon != undefined) {
                icon.addClass('fa-plus-circle');
                icon.removeClass('fa-spinner fa-pulse');
            }

            modal.find('.modal-dialog').html(data.html);
            modal.find('form').on('submit', function(e){
                e.preventDefault();
                newUpdateModal(modal, jQuery(this).serialize(), null, triggerReload);
            });

            if(modal.is(':visible')) {
                remoteCallback(modal);
            } else {
                modal.one('shown.bs.modal', function(){
                    remoteCallback(modal);
                });
                modal.modal('show');
            }
        }
    });
}