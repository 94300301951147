(function ($) {
    $(function () {

        function initializeModal(modal) {
            const $modal = $(modal);

            $modal.find('form #cenas_form_user_type_roles').change(function (e) {
                showOrHideRccField($modal);
            });

            $modal.on('modal:updated', function() {
                showOrHideRccField($modal);
            });

            showOrHideRccField($modal);
        }

        const $modalUserCreateEdit = $('.alpixel_modal_user_create, .alpixel_modal_user_edit');
        $modalUserCreateEdit.on('show.bs.modal', function (ev) {
            initializeModal(ev.target);
        });

        $modalUserCreateEdit.on('modal:updated', function (ev) {
            initializeModal(ev.target);
        });

        /**
         * Permet d'afficher ou de cacher le champ RCC si on sélectionne un médecin
         */
        function showOrHideRccField($modal) {
            const $form = $modal.find('form');
            const $user_role = $form.find('#cenas_form_user_type_roles');
            const $input_rccAmbulatoire = $form.find('#cenas_form_user_type_rccAmbulatoire');
            const $input_rcc = $form.find('#cenas_form_user_type_rcc');
            const $input_gln = $form.find('#cenas_form_user_type_gln');

            const display = $user_role.val() === 'ROLE_DOCTOR' || $user_role.val() === 'ROLE_DOCTOR_PLUS';
            if (display) {
                $input_rccAmbulatoire.parent().removeClass('hidden')
                $input_rcc.parent().removeClass('hidden')
                $input_gln.parent().removeClass('hidden')
            } else {
                $input_rccAmbulatoire.parent().addClass('hidden')
                $input_rcc.parent().addClass('hidden')
                $input_gln.parent().addClass('hidden')

            }
        }
    });
})(jQuery);