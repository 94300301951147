(function($) {
    $(function() {
        function classifyReports(urlClassify, reportsId) {
            $.get(urlClassify, {reports: reportsId})
            .done(function(data){
                location.reload();
            });
        }

        // Filter "Transmis & A transmettre"
        if($('select[id="report_validated_filter"]').length) {
            var _select = $('select[id="report_validated_filter"]');
            var uriDest = basePath+'/examination/list/report/';

            _select.on('change', function(e){
                var val = $(this).val();
                if(val == '0')
                    window.location = uriDest + '2'
                else if(val == '1')
                    window.location = uriDest + '3';
            });
        }

        // Classify one or more reports
        if($('button[data-url-classify]').length) {
            var buttonClassify = $('button[data-url-classify]');
            var urlClassify    = buttonClassify.attr('data-url-classify');

            buttonClassify.on('click', function(e){
                if($('input[type="checkbox"][name="reports[]"]:checked').length) {
                    var reportsId = [];

                    $('input[type="checkbox"][name="reports[]"]:checked').each(function(index, element){
                        reportsId.push($(element).val());
                    });
                    if(reportsId.length) {
                        classifyReports(urlClassify, reportsId);
                    }
                }
            })

            if($('button.report-classify').length) {
                $('button.report-classify').on('click', function(e){
                    var buttonClassifyOne = $(this);
                    var input             = buttonClassifyOne.parent().parent().find('input[type="checkbox"][name="reports[]"]');

                    if(input.length) {
                        classifyReports(urlClassify, [input.val()])
                    }
                })
            }
        }
    });
})(jQuery);
