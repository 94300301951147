function updateInputForm(element, inputHidden, inputDisplay) {
    var dataTable    = jQuery(element).find('.dataTables_wrapper');
    var currentModal = jQuery(element);
    jQuery(dataTable).on('click', '.chooseBtn', function(e){
        jQuery(inputHidden).val(jQuery(this).attr('data-id'));
        jQuery(inputDisplay+' input[type="text"]').val(jQuery(this).attr('data-text'));
        jQuery(currentModal).modal('hide');
    });

    var dataTable2    = jQuery(element).find('.searchDataPagin');
    jQuery(dataTable2).on('click', '.chooseBtn', function(e){
        jQuery(inputHidden).val(jQuery(this).attr('data-id'));
        jQuery(inputDisplay+' input[type="text"]').val(jQuery(this).attr('data-text'));
        jQuery(currentModal).modal('hide');
    });
}

function toggleHasFoodAllergy(hasFoodAllergy, foodAllergyDescription, data) {
	if (data && data.length > 0) {
		hasFoodAllergy.parent().removeClass('hidden');
		foodAllergyDescription.parent().removeClass('hidden');
	} else {
		hasFoodAllergy.parent().addClass('hidden');
		foodAllergyDescription.parent().addClass('hidden');
		hasFoodAllergy.find('input[name="examination[hasFoodAllergy]"][value=0]').prop('checked', true);
		foodAllergyDescription.prop('disabled', true);
	}
}

function toggleFoodAllergyDescription(hasFoodAllergy, foodAllergyDescription) {
	var hasFoodAllergyVal = hasFoodAllergy.find('input[name="examination[hasFoodAllergy]"]:checked').val();
	foodAllergyDescription.prop('disabled', hasFoodAllergyVal != 1);
}

(function($){
    $(function(){

        //Plugins javascript lors de l'ouverture de la modal
        var downtimeModal = $('.alpixel_form_modal.alpixel_modal_agenda_unavailable_create');
        downtimeModal.on('modal:updated', function(e) {
            downtimeModal.find('#room_indisponibility_scheduled').datepicker(datepickerOptions);
        });

        //Plugins javascript lors de l'ouverture de la modal
        var creationActiModal = $('.alpixel_form_modal.alpixel_modal_agenda_create_acti, .alpixel_form_modal.editAgendaItem');
        creationActiModal.on('modal:updated', function(e) {
            creationActiModal.find('#examination_dateGrant').datepicker(datepickerOptions);
            creationActiModal.find('#examination_dateReturn').datepicker(datepickerOptions);

            var creationModal1 =  $('.alpixel_form_modal.alpixel_modal_agenda_create');
            creationModal1.find('.modal-content').html('');
        });

        //Plugins javascript lors de l'ouverture de la modal
        var creationModal = $('.alpixel_form_modal.alpixel_modal_agenda_create, .alpixel_form_modal.editAgendaItem');
        creationModal.on('modal:updated', function(e) {
            creationModal.find('.select2').select2();
            creationModal.find('#examination_scheduled').datepicker(datepickerOptions);

			var foodAllergyDescription = creationModal.find('#examination_foodAllergyDescription');
			var hasFoodAllergy = creationModal.find('#examination_hasFoodAllergy');
			toggleFoodAllergyDescription(hasFoodAllergy, foodAllergyDescription);
			hasFoodAllergy.on('change', function(e){
				toggleFoodAllergyDescription(hasFoodAllergy, foodAllergyDescription);
			})
			var testsOther = creationModal.find('#examination_testsother');
			toggleHasFoodAllergy(hasFoodAllergy, foodAllergyDescription, testsOther.select2('data'));
			testsOther.on('select2:select select2:unselect', function (e) {
				toggleHasFoodAllergy(hasFoodAllergy, foodAllergyDescription, testsOther.select2('data'));
			});

            var psg = creationModal.find('#examination_psg');
            var optionPsg = creationModal.find('#examination_testspsg').parent();
            if(psg.val() == 1){
                optionPsg.removeClass('hidden')
            }else{
                optionPsg.addClass('hidden');
            }
            psg.change(function() {
                if(psg.val() == 1){
                    optionPsg.removeClass('hidden')
                }else{
                    optionPsg.addClass('hidden');
                }
            });
            $('.examenRevive').on('click', function (e) {
                $('#alpixelFormModal_reviveExamen').attr('data-source', $(this).data('href'));
                newUpdateModal($('#alpixelFormModal_reviveExamen'), null, null, 'exam:revive');

                $(window).on('exam:revive', function () {
                    // trigger de retour
                    document.location.reload();
                });
            });

            var creationModal1 =  $('.alpixel_form_modal.alpixel_modal_agenda_create_acti');
            creationModal1.find('.modal-content').html('');
        });

        //Enregistrement des recherches en submodal
        $('#searchPatientModal').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#examination_patient', '#patient-form-field');
            $(this).on('click', '.chooseBtn', function(e) {
                var sms = $(this).attr('data-sms');
                var email = $(this).attr('data-email');
                if (sms == "false") {
                    $("#examination_notification_0").prop("disabled",true);
                    $("#examination_notification_0").prop("checked",false);
                } else {
                    $("#examination_notification_0").prop("disabled",false);
                }
                if (email == "false") {
                    $("#examination_notification_1").prop("disabled",true);
                    $("#examination_notification_1").prop("checked",false);
                } else {
                    $("#examination_notification_1").prop("disabled",false);
                }
            });
        });

        $('#patientTypeModal').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#cenas_device_state_history_type_patient', '#patient-form-field');
        });

        $('#searchUserModal').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#examination_contact', '#contact-form-field');
        });

        $('#searchPatientAgenda').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#filter_examination_patient', '#patient-form-field-agenda');
        });

        $('#searchUserAgenda').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#filter_examination_user', '#contact-form-field-agenda');
        });

        $('#searchPatientMergeModal').on('show.bs.modal', function(e)
        {
            updateInputForm(this, '#cenas_patient_merge_form_patient', '#patient-form-field');
        });
    });
})(jQuery);
