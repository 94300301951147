
(function ($) {
    $(function () {
        function switchMode(stateModal) {
            var stateSale = stateModal.find('#cenas_device_state_history_type_state_sale').parent();
            var stateAssign = stateModal.find('#cenas_device_state_history_type_state_assign').parent();
            var stateConsignment = stateModal.find('#cenas_device_state_history_type_state_consignment').parent();
            var radioValue = parseInt($("input[name='cenas_device_state_history_type[distributionMode]']:checked").val());
            switch(radioValue) {
                case 1:
                    stateSale.removeClass('hidden');
                    stateAssign.addClass('hidden');
                    stateConsignment.addClass('hidden');
                    break;
                case 2:
                    stateSale.addClass('hidden');
                    stateAssign.removeClass('hidden');
                    stateConsignment.addClass('hidden');
                    break;
                case 3:
                    stateSale.addClass('hidden');
                    stateAssign.addClass('hidden');
                    stateConsignment.removeClass('hidden');
                    break;
            }
            stateModal.find('#cenas_device_state_history_type_state_sale').parent().parent().removeClass('hidden');
        }

        $(document).on('click', '#createModel', function (e) {
            e.preventDefault();
            var create = $('.alpixel_modal_stock_cenas_devicemodel_create');
            create.trigger('click');
            create.on('modal:show', function (e) {

            });
        });

        var officine = $('#cenas_device_filter_officine, #cenas_consumable_filter_officine');

        if (officine.length) {

            if ($('#cenas_device_filter_officine_all').is(':checked')) {
                officine
                    .val(officine.find('option:first').val())
                    .attr('disabled', 'disabled');
            }

            /*
             * Disable <select> tag ('#cenas_device_filter_officine') when the checkbox
             * 'Tout les cabinets' has been selected for the page 'Gérer les stocks'
             */
            $('form[name="cenas_device_filter"]').on('click', '#cenas_device_filter_officine_all, #cenas_consumable_filter_officine_all', function () {
                var _this = $(this);

                if (_this.is(':checked')) {
                    officine.val(officine.find('option:first').val()).attr('disabled', 'disabled');
                } else {
                    officine.removeAttr('disabled');
                }
            });
        }

        var stateModal = $('.alpixel_form_modal.alpixel_modal_patient_file_device_change, .alpixel_form_modal.alpixel_modal_stock_cenas_devices_state');
        stateModal.on('modal:updated', function(e) {

            var mode = stateModal.find('#cenas_device_state_history_type_distributionMode');
            switchMode(stateModal);
            mode.change(function() {
                switchMode(stateModal);
            });
        });
    });
})(jQuery);
