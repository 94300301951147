(function ($) {
    var addConsumableBtn = $('button.addConsumable');
    var inputConsumable = $('input[name="cenas_patient_consumable_transform[consumable]"]');

    $(addConsumableBtn).on('click', function () {
        var consumableId = $(this).attr('data-consumable');
        $(inputConsumable).val(consumableId);
    });

    // // Plugins javascript lors de l'ouverture des 2 modals (:updated) afin de charger le js et initialiser datepicker
    // $('.alpixel_modal_patient_file_consumable_add_list').on('modal:updated', function(e) {
    // 	var creationActiModal = $('.alpixel_modal_patient_file_consumable_add_form');
    // 	creationActiModal.on('modal:updated', function(e) {
    // 		creationActiModal.find('#cenas_patient_consumable_transform_created').datepicker(datepickerOptions);
    // 	});
    // });

    /**
     * Modal who list consumables from current stock
     */
    $(document).on('show.bs.modal', 'div.alpixel_form_modal.alpixel_modal_patient_file_consumable_add_list', function (modalListEvent) {
        var modalAddListConsumable = $(this);
        var locationAddListConsumable = $(this).attr('data-source');

        /**
         * Modal to add a consumable for a patient
         */
        $(document).on('show.bs.modal', 'div.alpixel_modal_patient_file_consumable_add_form.alpixel_form_modal', function (modalAddEvent) {
            const $modalAdd = $(this);
            onConsumableSubmit($modalAdd, $modalAdd.attr('data-source'), function(nb) {
                if (nb === false) {
                    return
                }

                modalAddListConsumable.find('.modal-alert').html(
                    '<div class="alert alert-success">' +
                    'Le consommable a bien été ajouté.' +
                    '</div>'
                );

                // ** Décrémente le stock de la modale PARENTE Modal > td > td * 2 avant   **
                const $tdStockConsumable = $modalAdd.parent().prev().prev();
                nb = $tdStockConsumable.html() - nb;
                $tdStockConsumable.html(nb);
                // **********************************************

                $modalAdd.modal('hide');

                setTimeout(function () {
                    modalAddListConsumable.find('.modal-alert').html(' ');

                    /**
                     * Update the rows for assigned patient consumable after elasticache
                     */
                    setTimeout(function () {
                        var locationListConsumable = $('#patient-list-consumables').attr('data-location');
                        $.get(locationListConsumable, function (response) {
                            $('#patient-list-consumables').find('tbody').html(response);
                        });
                    }, 1500);
                }, 1500);

                /**
                 * The setTimeout function is used to allow the time for Elastica to index data
                 * $.get update the list of consumable from stock after assignation
                 */
                //$.get(locationAddListConsumable).done(function(data) {
                // if (data.hasOwnProperty('html')) {
                //     modalAddListConsumable.find('table').DataTable().destroy();
                //     modalAddListConsumable.find('.modal-dialog').html(data.html);
                //     modalAddListConsumable.find('table').DataTable();
                // }
                //
                // /**
                //  * These following line is workaround for the .modal-backdrop due to the loading of modal from ajax request
                //  */
                // $('body').find('.modal-backdrop').last().remove();
                //});
            });
        });
    });

    function onConsumableSubmit($dom, url, callback) {
        const $form = $dom.find('form[name="cenas_patient_consumable_transform"]');
        const $btnSubmit = $form.find('input[type="submit"]');

        /**
         * Add consumable with ajax request
         */
        $btnSubmit.off().on('click', function (event) {
            event.preventDefault();

            $.ajax({
                type: 'POST',
                url: url,
                data: $form.serialize()
            }).done(function (data) {
                console.log(data)
                if (data.submitted === false || data.errors > 0) {
                    const $content = $form.closest('.modal-dialog').html(data.html);
                    onConsumableSubmit($content, url, callback);

                    if (typeof callback === 'function') {
                        callback(false);
                    }

                    return false;
                }

                const nb = $form.find('input[type="number"]').val();
                if (typeof callback === 'function') {
                    callback(nb);
                }

                return nb;
            });
        });
    }

    $(function () {
        $('.alpixel_modal_patient_file_consumable_add_list').on('modal:updated', function (e) {
            var dataTable = $(this).find('table.patientConsomableDatatable');

            if (dataTable.hasClass('dataTable') == false) {
                dataTable.DataTable('destroy');
            }
        });
    });
})(jQuery);
