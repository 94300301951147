(function($) {
    $(function () {

        var select2Opts = {
            escapeMarkup: function (markup) { return markup; },
            templateResult: formatSupplier,
            //templateSelection: formatName,
            ajax: {
                url: basePath+'/stock/consumable/search',
                dataType: 'json',
                delay: 250,
                minimumInputLength: 1,
                data: function (params) {
                    return {
                        queryString: params.term,
                    };
                },
                processResults: function (data, page) {
                    return {
                        results: data
                    };
                },
            },
        };

        var creation = $('.alpixel_stock_supplier_delivery_create');
        creation.find('.add-item').on('click', function(e){
            e.preventDefault();

            var fieldList = $('#consumable-field-list');
            var newWidget = fieldList.attr('data-prototype');
            var currentCount = $('#consumable-field-list li').length;

            newWidget = newWidget.replace(/__name__/g, currentCount);
            currentCount++;

            var newLi = $('<li></li>').html(newWidget);
            var newLi = newLi.appendTo(fieldList);

            $(newLi).find('.supplier_autocomplete').select2(select2Opts);

            supplier(creation,select2Opts);
            return false;
        });
    });

    function supplier(creation,select2Opts) {
        creation.find('.supplier_autocomplete').select2(select2Opts);

    }


    function formatName (obj) {
        console.log(obj);
        if (obj.loading) return obj.text;
        var markup = "<label>";
        if(obj.name != null)
            markup += obj['name'];
        if(obj['type'] != null)
            markup += " - "+obj["type"];
        markup += "chat</label>";
        return markup;
    }

    function formatSupplier (obj) {
        console.log(obj);

        if (obj.loading) return obj.text;

        var markup = '<div class="clearfix">' +
        '<div clas="col-sm-12">' +
        '<div class="clearfix">' +
        '<div class="col-sm-6">';

        if(obj.brand != null)
            markup += '<strong>' + obj.brand + '</strong><br />';

        markup += obj.reference;

        if(obj.name != null)
            markup += ' : ' + obj['name'];

        markup += '</div>';

        markup += '<div class="col-sm-2">';
        if(obj.price != null)
            markup += 'CHF ' + obj.price;
        markup += '</div>';

        markup += '<div class="col-sm-2">';
        if(obj.model != null)
            markup += '<i class="fa fa-star"></i> '+ obj.model;
        markup += '</div>';

        markup += '<div class="col-sm-2">';
        if(obj['type'] != null)
            markup += '<i class="fa fa-star"></i> '+ obj['type'];
        markup += '</div>';

        markup += '</div>';
        markup += '</div></div>';

        return markup;
    };

})(jQuery);
