(function($) {
    $(function () {
        $('input[name="period_start"]').datepicker(datepickerOptions);
        $('input[name="period_end"]').datepicker(datepickerOptions);

        $('.massValidate').on('change', function(e){
            if($(this).prop('checked') == true) {
                $(this).parentsUntil('tr').parent().addClass('active');
            } else {
                $(this).parentsUntil('tr').parent().removeClass('active');
            }
        });

        $('#validateBill').on('click', function(e){
            e.preventDefault();
            $('.tab-pane:not(.active) .massValidate').prop('checked', false);
            $('#validateExport').trigger('submit');
        });
    });
})(jQuery);
