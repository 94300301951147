(function ($) {
    $(function () {

        var currentViewType = 'basicWeek';
        var currentDayStart = moment();
        var cookie = readCookie('agenda_view');
        document.cookie = "agenda_view="+encodeURIComponent("")+";";
		var scheduledFromSession = $("#calendar").data("scheduled");
        var CST_EXAMINATION = 'examination';
        var CST_RESERVED = 'reserved';
        var CST_BLOCKED = 'blocked';
        var CST_UNAVAILABLE = 'unavailable';
        var CST_AVAILABLE = 'available';
        var CST_DISABLED = 'disabled';

        if (cookie != undefined && cookie != "") {
            var view = atob(cookie).split('#####');
            currentDayStart = moment.unix(view[0]);
            currentViewType = view[1];
        }
		if (scheduledFromSession != undefined && scheduledFromSession != "") {
			var momentFromSession = moment(scheduledFromSession, "YYYY-MM-DD");
			if(momentFromSession.isValid()) {
				currentDayStart = momentFromSession
			}
		}

        //Calendrier principal de la page
        $('#calendar').fullCalendar({
            height: 'auto',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,basicWeek,year,list'
            },
            defaultView: currentViewType,
            defaultDate: currentDayStart,
            lang: 'fr',
            firstDay: 1,
            aspectRatio: 3,
            columnFormat: 'ddd D/M',
            buttonText: {
                today: 'Aujourd\'hui',
                month: 'Mois',
                week: 'Semaine',
                day: 'Jour',
                year: 'Année'
            },
            events: basePath + '/agenda-' + $("#calendar").data("type") + '/datalist',
            viewRender: function (view, element) {

				$('#calendar').fullCalendar('removeEvents');
                $(element).find('tbody td').css('cursor', 'pointer');
                currentDayStart = view['intervalStart'];
                currentViewType = view['type'];
				document.cookie = "agenda_view=" + btoa(currentDayStart.unix() + '#####' + currentViewType) + ";";
            },
            dayClick: function (data, event, view) {
                $('.alpixel_modal_agenda_create').one('shown.bs.modal', function (e) {
                    $('#examination_scheduled').val(data.format('YYYY-MM-DD'));
                    document.cookie = "agenda_view=" + btoa(currentDayStart.unix() + '#####' + currentViewType) + ";";
                });
				$('.alpixel_modal_agenda_create_acti').one('shown.bs.modal', function (e) {
					$('#examination_dateGrant').val(data.format('YYYY-MM-DD'));
					document.cookie = "agenda_view=" + btoa(currentDayStart.unix() + '#####' + currentViewType) + ";";
				});

                $('.alpixel_modal_agenda_create,.alpixel_modal_agenda_create_acti').trigger('click');
            },
            eventClick: function (data, event, view) {
                event.preventDefault();
                if (data.url == undefined)
                    return;
                $('#alpixelFormModal_editAgendaItem').attr('data-source', data.url);
                updateModal($('#alpixelFormModal_editAgendaItem'), null);
            },
			eventRender: function(event, element, view) {
				// Fullcalendar strip les caractères HTML, il faut manuellement remettre le texte brut dans la balise fc-title
				$(element[0]).find('.fc-title').html(event.title.replace(/[\n\r]/g,"<BR>"));
			},
        });

        $('#alpixelFormModal_editAgendaItem').on('modal:reload', function (e) {
            document.cookie = "agenda_view=" + btoa(currentDayStart.unix() + '#####' + currentViewType) + ";";
        });

        $('#alpixelFormModal_editAgendaItem, #alpixel_modal_agenda_create').on('modal:updated', function (e) {
            $("#alpixelFormModal_editAgendaItem, #alpixel_modal_agenda_create").find(".confirm-button").confirm();
        });


        // $('#calendar').fullCalendar( 'gotoDate', date )
        // $('#calendar').fullCalendar( 'changeView', viewName )

        //Gestion des filtres de l'agenda
        $('#filterCalendarForm').find('.select2').select2();

        $('#filter_examination_reset').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var form = document.getElementById('filterCalendarForm');
            form.reset();
            $('#filter_examination_user').val(null);
            $('#filter_examination_patient').val(null);
			updateInputForm(this, '#filter_examination_user', '#contact-form-field-agenda');
			updateInputForm(this, '#filter_examination_patient', '#patient-form-field-agenda');

            $(form).trigger('submit');
        });

        $('#filterCalendarForm').on('submit', function (e) {

            var calendarView = $('#calendar').fullCalendar('getView');
            var dateStart = calendarView.start.toLocaleString();
            var dateEnd = calendarView.end.toLocaleString();

            $(this).find('#filter_examination_date_start').val(dateStart);
            $(this).find('#filter_examination_date_end').val(dateEnd);



            $('#filterCalendar i').removeClass('hidden');
            if (parseInt($(this).find('#filter_examination_isForPrinting').val()) === 1){
                    $('#filterCalendar i').addClass('hidden');
            } else {
                e.preventDefault();
                $.post(basePath + '/agenda-' + $("#calendar").data("type") + '/datalist', $(this).serialize(), function (data) {
                    $('#calendar').fullCalendar('removeEvents');
                    $('#calendar').fullCalendar('addEventSource', data);
                    $('#filterCalendar i').addClass('hidden');
                });
            }
        });

        $('#calendar_print').on('click', function (e) {

            /*var calendarView = $('#calendar').fullCalendar('getView');
            var dateStart = calendarView.start.toLocaleString();
            var dateEnd = calendarView.end.toLocaleString();

            $(this).find('#filter_examination_date_start').val(dateStart);
            $(this).find('#filter_examination_date_end').val(dateEnd);

            $.post(basePath + '/agenda/print', $('#filterCalendarForm').serialize());*/

            $(document).find('#filter_examination_isForPrinting').val(1);
            $('#filterCalendarForm').attr('target','_blank');
            $('#filterCalendarForm').submit();
            $(document).find('#filter_examination_isForPrinting').val(0);

        });


        // ======== Calendar room unavailable ===========
        // **
        $('#calendarRoomUnavailable').fullCalendar({
            height: 'auto',
            header: {
                left : '',
                center: 'title',
                right: ''
            },
            defaultView: 'year',
            defaultDate: getDateFilterRoomAvailable(),
            lang: 'fr',
            firstDay: 1,
            columnFormat: 'ddd D/M',
            selectable : false,
            weekends: true,
            navLinks : false,
            events: basePath + '/agenda-' + $("#calendarRoomUnavailable").data("type") + '/datalist/room_available',
            viewRender: function (view, element) {
                if ($('#calendarRoomUnavailable').hasClass('modify')) {
                    $(element).find('tbody td').css('cursor', 'pointer');
                } else {
                    $(element).find('tbody td').css('cursor', 'no-drop');
                }
                currentDayStart = view['start'];
                currentViewType = view['type'];

            },
            eventRender: function(event,element){
                if(event.rendering === "background"){
					element.data(event); //store the event data inside the element
                }
            },
            eventAfterAllRender: function (view) {
                // Count events
                //var quantity = $('.fc-event').length;
				$('.btn-note-edit').remove();
				$('.fc-day-number').not('.fc-other-month').each(function(){
					var date = $(this).data('date');
					var event = getEventFromDate(date);
					if (event === null) return;
					if (event.hasNote || event.unavailable) {
						var picto = event.hasNote ? 'fa-edit' : 'fa-plus';
						var btn = event.hasNote ? 'btn-success' : 'btn-primary';
						var add_button = $('<a style="position: absolute; top: 4px; right: 4px;" class="btn btn-xs '+btn+' btn-note-edit" data-event-id="'+event.id+'"><i class="fa '+picto+'"></i></a>');
						add_button.click(function(){
							var eventData = event.id.split('-');
							var eventType = eventData[0];
							var id = eventData[1];
							var url = basePath + '/agenda-' + $("#calendarRoomUnavailable").data("type") + '/unavailable/edit/' + id;
							$('#alpixelFormModal_editAgendaItem').attr('data-source', url);
							$('#alpixelFormModal_editAgendaItem').one('shown.bs.modal', function (e) {
								$('#room_indisponibility_scheduled').val(date);
								$('#room_indisponibility_room').val($('#filter_room_available_room').val());
							});
							updateModal($('#alpixelFormModal_editAgendaItem'));
						})
						$(this).append(add_button);
						$(this).css('position', 'relative');
					}
				});
            },
            dayClick: function (date, jsEvent, view) {
				if(!$(jsEvent.target).hasClass('fc-day-number')) return true;

                if ($('#calendarRoomUnavailable').hasClass('modify')) {
                    var event = getEventFromDate(date);
					var dateIso = date.toISOString().slice(0, 10);
                    var dateId = dateIso.replace(/-/g, "");
                    var background_color = "#ff0000";
                    if (event !== null) {
                        // Room locked for the day clicked
                        var idSplitted = event.id.split('-');
                        var event_type = idSplitted[0];
                        var idSuffix = '-' + idSplitted[2];
                        if (idSuffix === '-' + undefined) {
                            idSuffix = '';
                        }
                        if (event_type !== CST_EXAMINATION && event_type !== CST_RESERVED && event_type !== CST_DISABLED) {
                            // Room is not blocked and don't have any schedule on it
                            if (event_type === CST_BLOCKED) {
                                idSuffix = '-blocked';
                            }
							$('#calendarRoomUnavailable').fullCalendar('removeEvents', event.id);
                            background_color = "#66ff66";
                            var idCustom = 'available-' + dateId;
							var unavailable = false;
                            if (event_type === CST_UNAVAILABLE) {
                                // Room was blocked by user without save
                                if (idSuffix !== '') {
                                    // Room was saved blocked before
                                    background_color = "#999";
                                    idCustom = 'blocked-' + dateId;
                                } else {
                                    // Nothing special with that room before
                                    background_color = "#fff";
                                    idCustom = 'available-' + dateId;
                                }
                            } else if (event_type === CST_AVAILABLE) {
                                if (idSuffix !== '') {
                                    background_color = "#999";
                                    idCustom = 'blocked-' + dateId;
									unavailable = true;
                                } else {
                                    // Room was available
                                    idCustom = 'unavailable-' + dateId;
                                    background_color = "#ff0000";
									unavailable = true;
                                }
                            }
                            $('#calendarRoomUnavailable').fullCalendar('renderEvent', {
                                id: idCustom + idSuffix,
                                title: '',
                                start: date,
                                allDay: true,
								unavailable: unavailable,
                                rendering: 'background',
                                backgroundColor: background_color
                            }, true);
                        }
                    } else {
                        // Room blocked by user without save
                        $('#calendarRoomUnavailable').fullCalendar('renderEvent', {
                            id: 'unavailable-' + dateId,
                            title: '',
                            start: date,
                            allDay: true,
                            rendering: 'background',
							unavailable: true,
                            backgroundColor: '#ff0000'
                        }, true);
                    }
                }
            },
            editable:false,
        });

        if ($('#calendarRoomUnavailable').hasClass('modify')) {
            $('#filterCalendar').prop('disabled',true);
        }

        $('#filterCalendarRoomAvailableForm').on('submit', function (e) {
            e.preventDefault();

            $('#calendarRoomUnavailable').fullCalendar( 'gotoDate', getDateFilterRoomAvailable() );

            var calendarView = $('#calendarRoomUnavailable').fullCalendar('getView');
            var dateStart = calendarView.start.toLocaleString();
            var dateEnd = calendarView.end.toLocaleString();

            $(this).find('#filter_room_available_date_start').val(dateStart);
            $(this).find('#filter_room_available_date_end').val(dateEnd);



            $('#filterCalendarRoomAvailableForm i').removeClass('hidden');
            $.post(basePath + '/agenda-' + $("#calendarRoomUnavailable").data("type") + '/datalist/room_available', $(this).serialize(), function (data) {
                $('#calendarRoomUnavailable').fullCalendar('removeEvents');
                $('#calendarRoomUnavailable').fullCalendar('addEventSource', data);

                $('#filterCalendar i').addClass('hidden');
            });
        });

        $('#CalendarRoomAvailableSave').on('click', function (e) {

            var allEventsRaw = $('#calendarRoomUnavailable').fullCalendar('clientEvents');

            var allEvents = [];

            allEventsRaw.forEach(function(eventRaw) {
                var eventToPush = [];
                allEvents.push({id: eventRaw['id'],start : formatDateYmd(new Date(eventRaw['start']),'-')});
            });

            var objectToPOST = {roomId : $('#filter_room_available_room').val(),roomIndisponibility : allEvents};

            $.ajax({
                type: 'POST',
                url: basePath + '/agenda-' + $("#calendarRoomUnavailable").data("type") + '/room/unavailable/save',
                data: JSON.stringify(objectToPOST),
                success: function(data) {
                    var currentUrl = window.location.href;
                    console.log(data);
                    var urlToGo = currentUrl.substring(0,currentUrl.lastIndexOf('/modify'));
                    window.location.replace(urlToGo);
                },
                contentType: "application/json",
                dataType: 'json'
            });
        });

        $('#CalendarRoomAvailablePrint').on('click', function (e) {

            var date = getDateFilterRoomAvailable();
            var roomId  = $('#filter_room_available_room').val();
            if (roomId < 1) {
                roomId = -1;
            }

            window.open(
                basePath + '/agenda-' + $("#calendarRoomUnavailable").data("type") + '/room/unavailable/print/'+roomId+'/'+date,
                '_blank'
            );
        });

        if ($('#filterCalendarRoomAvailableForm #filterCalendar').length > 0) {
            $('#filterCalendar').trigger("click");
        }

        $( "#filter_room_available_room" ).change(function() {
            $('#filterCalendar').trigger("click");
        });

        $( "#filter_room_available_year" ).change(function() {
            $('#filterCalendar').trigger("click");
        });

		$('.alpixel_modal_agenda_create_acti,.editAgendaItem').on('modal:updated', function (e) {
			const modal = e.target;

			// Il est préférable de rafraichir le select2 en cas d'update
			$('#examination_ambulDevice', modal).select2();

			// Gestion de la relation entre Examen et Equipement utilisé pour réalisé cet examen
			//

			$(':input[name="examination[test]"]', modal).on('change', function (e, doNotRefreshAmbulDevice) {
				const examinationTest = e.target;
				const examinationTestSelected = $(':input[name="examination[test]"]:checked', modal).val();

				if (examinationTestSelected != undefined) {

					// Si on avait un équipement sélectionné mais que celui-ci est incompatible avec l'examen qui vient d'être sélectionné, on le désélectionne
					const $equipementSelected = $('#examination_ambulDevice option:selected', modal);
					if ($equipementSelected != undefined && $equipementSelected.attr('data-test-id') != examinationTestSelected) {
						$('#examination_ambulDevice', modal).val("");
					}

					$('#examination_ambulDevice option', modal).prop('disabled', true);
					$('#examination_ambulDevice option[data-test-id=' + examinationTestSelected + ']', modal).prop('disabled', false);
				}
				else {
					$('#examination_ambulDevice option', modal).prop('disabled', false);
				}

				// Gestion des appareils de mesure disponibles selon les dates d'attribution et restitution saisies
				//

				var dateGrant = $('#examination_dateGrant', modal).val();
				var dateReturn = $('#examination_dateReturn', modal).val();
				var examinationId = $('#examination_id', modal).val();

				if (dateGrant != '' && dateReturn != '') {
					$.ajax({
						type: 'POST',
						url: basePath + '/agenda-ambulatoire/datalist/ambuldevice_available',
						data: JSON.stringify({
							'dateGrant': dateGrant,
							'dateReturn': dateReturn,
							'examinationId': examinationId,
						}),
						success: function(data) {
							if (data && Array.isArray(data)) {
								const equipementSelectedId = $('#examination_ambulDevice', modal).val();

								$('#examination_ambulDevice option', modal).each(function() {
									if(data.indexOf(parseInt($(this).val())) == -1) {
										$(this).prop('disabled', true);

										// Si on avait un équipement sélectionné mais que celui-ci est incompatible avec les dates saisies, on le désélectionne
										if (equipementSelectedId != '' && $(this).val() == equipementSelectedId) {
											window.alert('L\'appareil de mesure sélectionné est indisponible sur ces dates');
											$('#examination_ambulDevice', modal).val("");
											$('#examination_ambulDevice', modal).select2();
										}
									}
								});
							}
						},
						contentType: "application/json",
						dataType: 'json'
					});
				}

				// ------------------------------

				if (!doNotRefreshAmbulDevice) {
					// Refreshing..
					$('#examination_ambulDevice', modal).select2();
				}
			});
			$(':input[name="examination[test]"]', modal).first().trigger('change');


			$('#examination_ambulDevice', modal).on('change', function (e) {
				const $equipementSelected = $('option:selected', e.target);
				const examinationTestSelected = $(':input[name="examination[test]"]:checked', modal).val();
				if ($equipementSelected.attr('data-test-id') != undefined && $equipementSelected.attr('data-test-id') != examinationTestSelected) {
					const $examinationTestToSelect = $(':input[name="examination[test]"][value="' + $equipementSelected.attr('data-test-id') + '"]', modal);
					$examinationTestToSelect.prop("checked",true);
					$examinationTestToSelect.trigger('change', [true]);
				}
			});

			$('#examination_dateGrant,#examination_dateReturn', modal).on('change', function (e) {
				$(':input[name="examination[test]"]', modal).first().trigger('change');
			});

		});

        function getDateFilterRoomAvailable() {
            var year = $("#filter_room_available_year").val();
            var date = new Date();
            return date.setFullYear(year);
        }

        function getEventFromDate(date) {
            var eventToReturn = null;

            $('#calendarRoomUnavailable').fullCalendar('clientEvents', function(event) {
                var event_start = formatDateYmd(new Date(date),'-');
                var date_formated = formatDateYmd(new Date(event.start),'-');

                if(event_start === date_formated) {
                    eventToReturn = event;
                }
            });
            return eventToReturn;
        }

        function formatDateYmd(value,$delimiter)
        {
            var year = value.getFullYear();
            var month = (value.getMonth()+1) < 10 ? '0'+(value.getMonth()+1) : (value.getMonth()+1);
            var day = value.getDate() < 10 ? '0'+value.getDate() : value.getDate();
            return year  + $delimiter +
                month + $delimiter +
                day;
        }

    });
})(jQuery);
