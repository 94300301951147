(function ($) {
    $(function () {

        const patientId = $('#appointment_date').data('patient');

        $('input#relais_cenas:checkbox').change(function () {
            if (this.checked) {
                addRelaisCenasToPatient(patientId, true);
                // alert('checked');

            } else {
                addRelaisCenasToPatient(patientId, false);
                // alert('not checked');
            }
        });

        /**
         * @param {int} patientId
         * @param {boolean} isChecked
         */
        function addRelaisCenasToPatient(patientId, isChecked) {
            $.ajax({
                type: 'POST',
                url: basePath + '/patient/file/' + patientId + '/appointment/index/add/relais',
                data: {
                    isChecked: +isChecked
                },
                success: function (response) {}
            });
        }
    });
})(jQuery);