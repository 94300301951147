(function($) {
    $(function () {

        var resultPanelPrototype = '        <div class="panel panel-default">\n' +
            '            <div class="panel-heading">\n' +
            '                <h3 class="panel-title"></h3>\n' +
            '            </div>\n' +
            '            <table id="resultTable" class="table table-striped table-condensed table-hover">\n' +
            '                <tbody>\n' +
            '                </tbody>\n' +
            '            </table>\n' +
            '        </div>';

        function appendDataTable(dataTable, data)
        {
            $.each(data, function(i, el) {
                if(el !== null && typeof el === 'object') {
                    appendDataTable(dataTable, el);
                }
                else {
                    dataTable.append('<tr><td>'+i+'</td><td>'+el+'</td></tr>');
                }
            });
        }

        function refreshAllPanels(data)
        {
            $('.statsResult').empty();
            $.each(data, function(i, el) {
                $resultPanel = $(resultPanelPrototype);
                $resultPanel.find('.panel-title').html(el.label);
                $('.statsResult').append($resultPanel);
                var resultTable = $('.table tbody', $resultPanel);
                refreshDataTable(resultTable, el.data);
            });
        }

        function refreshDataTable(dataTable, data)
        {
            dataTable.html('');
            appendDataTable(dataTable, data);
        }

        var xhr, xhrFilter;

        $('.list-group:not(.export-group-items) a:not(.direct-link)').on('click', function(e){
            e.preventDefault();
            var link = $(this);

            if(!link.hasClass('active') && link.attr('data-source') != '#') {

                $('.list-group:not(.export-group-items) a:not(.direct-link)').removeClass('active');
                $('.list-group:not(.export-group-items) a:not(.direct-link) i').remove();
                link.addClass('active');
                link.append('&nbsp;<i class="fa fa-spinner fa-spin"></i>');

                if(xhr != undefined) {
                    xhr.abort();
                }

                if(xhrFilter != undefined) {
                    xhrFilter.abort();
                }

                xhr = $.get(link.attr('data-source'), function(data) {
                    link.find('i').remove();
                    refreshAllPanels(data);
                });

                xhrFilter = $.get(link.attr('data-filter'), function(data) {
                    $('#filterForm').html(data).find('.datepicker').datepicker(datepickerOptions);
                    $('#filterForm .select2').select2();
                    $('#filterForm form').on('submit', function(e){
                        e.preventDefault();
                        var form        = $(this);
                        var actionUri   = form.attr('action');

                        if(form.length === 0 || typeof actionUri === 'undefined' || actionUri.length === 0) {
                            console.error('Le formulaire ou l\'attribut action du formulaire n\'a pas été trouvé');
                        }

                        var resultTable = $('#resultTable tbody');
                        form.find('button[type="submit"]').html("<i class='fa fa-refresh fa-spin'></i> Recherche en cours...");
                        $.post(actionUri, form.serialize(), function(data){
                            refreshAllPanels(data);
                            form.find('button[type="submit"]').html("Rechercher");
                        });
                    });
                });
            }

        });


        $('.export-group-items a:not(.direct-link)').on('click', function(e){
            e.preventDefault();
            var link = $(this);

            if(!link.hasClass('active') && link.attr('data-source') != '#') {

                $('.export-group-items a:not(.direct-link)').removeClass('active');
                $('.export-group-items a:not(.direct-link) i').remove();
                link.addClass('active');
                link.append('&nbsp;<i class="fa fa-spinner fa-spin"></i>');

                if(xhr != undefined) {
                    xhr.abort();
                }

                if(xhrFilter != undefined) {
                    xhrFilter.abort();
                }

                xhrFilter = $.get(link.attr('data-filter'), function(data) {
                    link.find('i').remove();
                    $('#filterForm').html(data).find('.datepicker').datepicker(datepickerOptions);
                    $('#filterForm .select2').select2();
                });
            }

        });

    });
})(jQuery);
