(function($){
    $(function(){
        $('#checkConvocationSms').on('click', function (e) {
            $('.convocation_sms:checkbox').prop('checked', 'checked');
        });
        $('#checkConvocationMail').on('click', function (e) {
            $('.convocation_mail:checkbox').prop('checked', 'checked');
        });
        $('#checkConvocationPdf').on('click', function (e) {
            $('.convocation_pdf:checkbox').prop('checked', 'checked');
        });

        $('#checkConvocationClose').on('click', function (e) {
            $('.convocation_check:checkbox').prop('checked', false);
        });

        $('#appointmentDate').datepicker(datepickerOptions);

        var btnSubmit = $('#submitConvocation .btn-submit');

        $('#submitConvocation form').on('submit', function(e) {
            e.preventDefault();

            btnSubmit.attr('disabled', 'disabled');
            btnSubmit.prepend('<i class="fa fa-spin fa-spinner" />');
            $.post(basePath+'/patient/convocations/create', $('#submitConvocation form').serialize(), function(data){
                if(data['error'] == false) {
                    if(data['hasPDF'] == true) {
                        window.open(basePath+'/notification/pool/download');
                    }
                    document.location = basePath+'/patient/appointment';
                }
            });
        });

        $('.reissueConvocation').on('click', function(e) {
            e.preventDefault();

            $(this).attr('disabled', 'disabled');
            $(this).prepend('<i class="fa fa-spin fa-spinner" />');
            $.post(basePath+'/patient/convocation/reissue/'+$(this).data('id'),  function(data){
                if(data['error'] == false) {
                    if(data['hasPDF'] == true) {
                        window.open(basePath+'/notification/pool/download');
                    }
                    document.location.reload();
                }
            });
        });
        $('.reissuePdfConvocation').on('click', function(e) {
            e.preventDefault();

            $(this).attr('disabled', 'disabled');
            $(this).prepend('<i class="fa fa-spin fa-spinner" />');
            $.post(basePath+'/patient/convocation/reissues-pdf/'+$(this).data('id'),  function(data){
                if(data['error'] == false) {
                    if(data['hasPDF'] == true) {
                        window.open(basePath+'/notification/pool/download');
                    }
                    document.location.reload();
                }
            });
        });
    });
})(jQuery);
