/**
 * Created by alexis on 21/04/17.
 */
(function($) {
	$(function() {

		function XHRView() {
			this.view = null;
			this.parameters = null;
			this.target = null;
		}

		XHRView.DOM_SELECTOR =  {
			TARGET: 'x-target',
			URL: 'x-url',
			PARAMETERS: 'x-parameters'
		};

		XHRView.prototype.get = function (url, parameters, callback) {
			var self = this;

			if (typeof url === 'undefined') {
				throw new Error('You must define an url attribute.');
			}

			if (typeof parameters === 'undefined') {
				parameters = {};
			}

			$.get(url, parameters).done(function (data) {
				self.view = data.view;
				self.parameters = data.parameters;

				if (typeof callback === 'function') {
					callback();
				}
			});
		};

		XHRView.prototype.renderView = function (targetElement) {
			if (typeof targetElement === 'undefined') {
				throw new Error('Any target element defined');
			}

			$(targetElement).html(this.view);
		};

		XHRView.prototype.render = function (url, parameters, target) {
			var self = this;

			this.get(url, parameters, function () {
				self.renderView(target);
			});
		};


		$(document).on('click', '.xhr_view.xhr_view_render', function (ev) {
			var _XHRView = new XHRView();
			var url = $(this).data(XHRView.DOM_SELECTOR.URL);
			var parameters = $(this).data(XHRView.DOM_SELECTOR.PARAMETERS);
			var target = $(this).data(XHRView.DOM_SELECTOR.TARGET);

			_XHRView.render(url, parameters, target);
		});
	});
})(jQuery);

